.objects {
  padding-top: 115px;
}

.objects__container {
  @include container;
}

.objects__title {
  @include title;
  margin: 0;
  margin-bottom: 25px;
}

.objects__gallery {
  display: grid;
  grid-auto-rows: 190px;
  grid-gap: 10px;
  margin: 0;
  margin-bottom: 30px;
  padding: 0;
  list-style: none;
}

.objects__btn-more {
  margin-bottom: 50px;
}

@media (min-width: $breakpoint-tablet) {
  .objects {
    margin-bottom: 90px;
    padding-top: 150px;
  }

  .objects__title {
    margin-bottom: 35px;
  }

  .objects__gallery {
    grid-template-columns: repeat(auto-fit, 350px);
    grid-auto-rows: 280px;
    grid-gap: 20px;
    grid-auto-flow: dense;

    & .work__item {
      &--size-m {
        grid-column: span 2;
      }
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .objects {
    margin-bottom: 0;

    & .form {
      overflow: visible;
    }
  }

  .objects__title {
    margin-bottom: 60px;
  }

  .objects__gallery {
    grid-template-columns: repeat(auto-fit, 400px);
    grid-auto-rows: 90px;
    margin-bottom: 50px;

    & .work__item {
      grid-row: span 3;

      &--size-s {
        grid-row: span 4;
      }

      &--size-m {
        grid-column: auto;
      }

      &--size-l {
        grid-row: span 5;
      }
    }

    .objects__btn-more {
      margin-bottom: 0;
    }
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .objects__title {
    margin-bottom: 80px;
  }

  .objects__gallery {
    grid-template-columns: repeat(auto-fit, 540px);
    grid-auto-rows: 120px;
  }
}
