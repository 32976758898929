.vacancy {
  margin-bottom: 60px;
  padding-top: 115px;
}

.vacancy__container {
  @include container;
}

.vacancy__title {
  @include title;
  margin: 0;
  margin-bottom: 30px;
}

.vacancy__item {
  margin-bottom: 40px;
}

.vacancy__caption {
  margin: 0;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
}

.vacancy__heading {
  margin: 0;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
}

.vacancy__list {
  margin: 0;
  margin-bottom: 25px;
  padding: 0;
  list-style: none;

  & li {
    position: relative;
    margin-bottom: 5px;
    padding-left: 25px;
    font-size: 13px;
    line-height: 19px;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: 6px;
      left: 5px;
      display: block;
      width: 8px;
      height: 8px;
      background-color: $yellow;
      transform: rotate(45deg);
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .vacancy {
    margin-bottom: 100px;
    padding-top: 150px;
  }

  .vacancy__title {
    margin-bottom: 40px;
  }

  .vacancy__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 60px;
  }

  .vacancy__item {
    width: calc(50% - 20px);
    margin-bottom: 30px;
  }

  .vacancy__caption {
    margin-bottom: 25px;
    font-size: 24px;
    line-height: 26px;
  }

  .vacancy__heading {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 20px;
  }

  .vacancy__list {
    margin-bottom: 30px;

    & li {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .vacancy {
    & .form {
      overflow: visible;
    }
  }

  .vacancy__title {
    margin-bottom: 60px;
  }

  .vacancy__content {
    margin: 0;
  }

  .vacancy__caption {
    font-size: 28px;
    line-height: 30px;
  }

  .vacancy__heading {
    font-size: 20px;
    line-height: 22px;
  }

  .vacancy__list {
    margin-bottom: 25px;

    & li {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .vacancy__title {
    margin-bottom: 80px;
  }

  .vacancy__caption {
    font-size: 30px;
    line-height: 32px;
  }

  .vacancy__heading {
    font-size: 22px;
    line-height: 24px;
  }

  .vacancy__list {
    & li {
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 26px;

      &::before {
        top: 9px;
      }
    }
  }
}
