.fixing-elements {
  margin-bottom: 60px;
  padding-top: 115px;
}

.fixing-elements__container {
  @include container;
}

.fixing-elements__title {
  @include title;
  margin: 0;
  margin-bottom: 25px;
}

.fixing-elements__gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  margin-bottom: 40px;
}

.fixing-elements__image {
  position: relative;
  display: block;
  height: 200px;
  border: 1px solid $gray;

  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--video {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.2);
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      border: 25px solid $yellow;
      border-right: none;
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-left-width: 30px;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

.fixing-elements__subtitle {
  margin: 0;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.fixing-elements__list {
  margin: 20px 0;
  padding: 0;
  list-style: none;

  & li {
    position: relative;
    margin-bottom: 5px;
    padding-left: 20px;
    font-size: 13px;
    line-height: 19px;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: 6px;
      left: 3px;
      display: block;
      width: 8px;
      height: 8px;
      background-color: $yellow;
      transform: rotate(45deg);
    }
  }
}

.fixing-elements__desc {
  margin-bottom: 40px;

  & > p {
    margin: 0;
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 19px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.fixing-elements__table {
  width: 100%;
  margin-bottom: 40px;
  border: 1px solid $gray;
  border-collapse: collapse;
  table-layout: fixed;

  & th {
    padding: 10px;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid $gray;

    &:nth-child(1) {
      width: 45%;
    }

    &:nth-child(2) {
      width: 30%;
    }
  }

  & td {
    padding: 10px;
    font-size: 13px;
    line-height: 19px;
    border: 1px solid $gray;

    &:not(:first-child) {
      text-align: center;
      vertical-align: middle;
    }

    & span {
      font-weight: 700;
      color: red;
    }
  }
}

.fixing-elements__btn {
  font-size: 10px;
  background-color: $yellow;
  border: none;
}

p.fixing-elements__caption {
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.fixing-elements__text {
  margin: 0;
  margin-bottom: 20px;
  font-size: 13px;
  line-height: 19px;
}

.fixing-elements__table-gfk {
  margin-bottom: 40px;
}

.fixing-elements__gfk {
  border: 1px solid $gray;
}

.fixing-elements__heading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  margin: 0;
  padding: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #454547;
  background-color: $yellow;
}

.fixing-elements__gfk-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  padding: 30px 20px;
  justify-items: center;
  align-items: center;

  & img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  & > p {
    margin: 0;
    font-size: 13px;
    line-height: 19px;
  }
}

@media (min-width: $breakpoint-tablet) {
  .fixing-elements {
    margin-bottom: 100px;
    padding-top: 150px;
  }

  .fixing-elements__title {
    margin-bottom: 30px;
  }

  .fixing-elements__gallery {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    margin-bottom: 60px;
  }

  .fixing-elements__image {
    height: 325px;

    &--video {
      grid-column: span 2;
    }
  }

  .fixing-elements__subtitle {
    margin-bottom: 25px;
    font-size: 24px;
    line-height: 30px;
    text-align: left;
  }

  .fixing-elements__list {
    & li {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .fixing-elements__desc {
    margin-bottom: 60px;

    & > p {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .fixing-elements__table {
    & th {
      height: 80px;
      font-size: 16px;
      line-height: 20px;
    }

    & td {
      padding: 15px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .fixing-elements__btn {
    padding: 6px 20px;
    font-size: 14px;
    line-height: 20px;
    transition: all 0.2s;

    &:hover {
      box-shadow: 0 5px 15px rgba(19, 22, 26, 0.2);
      filter: brightness(120%);
      transition: all 0.2s;
    }
  }

  .fixing-elements__text {
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 20px;
  }

  .fixing-elements__table-gfk {
    margin-bottom: 80px;
  }

  .fixing-elements__gfk-content {
    & > p {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .fixing-elements {
    & .form {
      visibility: visible;
    }
  }

  .fixing-elements__title {
    margin-bottom: 60px;
  }

  .fixing-elements__gallery {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .fixing-elements__image {
    &--video {
      grid-column: span 1;
    }
  }

  .fixing-elements__subtitle {
    margin-bottom: 30px;
    font-size: 36px;
    line-height: 40px;
  }

  .fixing-elements__list {
    & li {
      padding-left: 25px;
      font-size: 16px;
      line-height: 24px;

      &::before {
        top: 8px;
        width: 10px;
        height: 10px;
      }
    }
  }

  .fixing-elements__desc {
    column-count: 2;
    column-gap: 50px;

    & > p {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .fixing-elements__table {
    margin-bottom: 60px;

    & th {
      font-size: 18px;
      line-height: 24px;
    }

    & td {
      padding: 20px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .fixing-elements__btn {
    font-size: 16px;
    line-height: 24px;
  }

  .fixing-elements__text {
    font-size: 16px;
    line-height: 24px;
  }

  p.fixing-elements__caption {
    font-size: 18px;
    line-height: 24px;
  }

  .fixing-elements__table-gfk {
    display: flex;
  }

  .fixing-elements__gfk {
    width: calc(100% / 3);
  }

  .fixing-elements__gfk-content {
    padding: 40px 30px;

    & > p {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .fixing-elements__title {
    margin-bottom: 80px;
  }

  .fixing-elements__gallery {
    margin-bottom: 80px;
  }

  .fixing-elements__image {
    height: 430px;
  }

  .fixing-elements__subtitle {
    margin-bottom: 40px;
  }

  .fixing-elements__list {
    & li {
      font-size: 18px;
      line-height: 26px;

      &::before {
        top: 9px;
      }
    }
  }

  .fixing-elements__desc {
    column-gap: 100px;

    & > p {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .fixing-elements__table {
    & th {
      font-size: 20px;
      line-height: 26px;
    }

    & td {
      padding: 20px 25px;
      font-size: 18px;
      line-height: 26px;

      &:first-child {
        padding-left: 100px;
      }
    }
  }

  .fixing-elements__btn {
    padding: 6px 30px;
    font-size: 18px;
    line-height: 26px;
  }

  .fixing-elements__text {
    font-size: 18px;
    line-height: 26px;
  }

  p.fixing-elements__caption {
    margin-top: 30px;
    font-size: 20px;
    line-height: 26px;
  }

  .fixing-elements__gfk-content {
    & > p {
      font-size: 18px;
      line-height: 26px;
    }
  }
}
