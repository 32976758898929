.button-more {
  position: relative;
  display: block;
  min-width: 120px;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  padding-bottom: 25px;
  font-family: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  background-color: transparent;
  border: none;

  &::before,
  &::after {
    content: "";
    position: absolute;
    bottom: 3px;
    left: 50%;
    display: block;
    width: 10px;
    height: 10px;
    margin-left: -5px;
    border-left: 1px solid $base-color;
    border-bottom: 1px solid $base-color;
    transform: rotate(-45deg);
  }

  &::before {
    bottom: 8px;
  }

  &:hover {
    &::before,
    &::after {
      border-color: $yellow;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .button-more {
    min-width: 150px;
    font-size: 16px;
    line-height: 18px;

    &::before,
    &::after {
      width: 14px;
      height: 14px;
      margin-left: -7px;
    }
  }
}
