.contacts {
  margin-bottom: 60px;
  padding-top: 115px;
}

.contacts__container {
  @include container;
}

.contacts__title {
  @include title;
  margin: 0;
  margin-bottom: 30px;
}

.contacts__tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 25px;
}

.contacts__btn {
  min-width: 190px;
  margin-bottom: 10px;
  color: #000000;

  &.active {
    background-color: $yellow;
  }
}

.contacts__content {
  display: none;

  &.active {
    display: block;
  }
}

.contacts__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.contacts__subtitle {
  width: 100%;
  margin: 0;
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
}

.contacts__address {
  width: 100%;
  margin: 0;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 24px;
}

.contacts__wrapper {
  min-width: 160px;
  margin: 0 5px;
  margin-bottom: 20px;

  & > p {
    margin: 0;
    margin-bottom: 10px;
  }
}

.contacts__phone {
  position: relative;
  display: block;
  padding-left: 30px;
  line-height: 24px;
  color: #000000;
  text-decoration: none;

  &::before {
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background: url("../images/png/phone.png") no-repeat center/contain;
  }
}

.contacts__mail {
  position: relative;
  display: block;
  padding-left: 30px;
  line-height: 24px;
  color: #000000;
  text-decoration: none;

  &::before {
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    display: block;
    width: 18px;
    height: 12px;
    background: url("../images/png/mail.png") no-repeat center/contain;
  }
}

.contacts__hot-line {
  margin: 0;
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  & a {
    margin-left: 5px;
    color: #000000;
    text-decoration: none;
  }
}

.contacts__image {
  display: none;
}

.contacts__map {
  width: 100vw;
  margin-left: -10px;
  margin-bottom: 60px;
  background-color: #f6f7f8;
}

.contacts__map-show {
  height: 290px;
}

.contacts__form.map__form {
  position: static;
  margin: 0;
  width: auto;
  background-color: transparent;
}

.contacts__buttons-show-map {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-gap: 20px;
  justify-items: center;
  margin-bottom: 40px;
}

.contacts__button-block {
  & h3 {
    margin: 0;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }

  & p {
    margin: 0;
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 19px;
  }

  & .button {
    margin-top: 10px;
    color: #000000;
  }
}

@media (min-width: $breakpoint-tablet) {
  .contacts {
    margin-bottom: 80px;
    padding-top: 150px;
  }

  .contacts__title {
    text-align: left;
  }

  .contacts__tabs {
    justify-content: flex-start;
    margin-bottom: 40px;
  }

  .contacts__btn {
    margin: 0;
    margin-right: 20px;
  }

  .contacts__content {
    &.active {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
    }
  }

  .contacts__col {
    width: 390px;
  }

  .contacts__subtitle {
    text-align: left;
  }

  .contacts__address {
    margin-bottom: 25px;
  }

  .contacts__block {
    justify-content: flex-start;
    margin-bottom: 15px;
  }

  .contacts__hot-line {
    margin: 0;
    text-align: left;
  }

  .contacts__image {
    flex-grow: 1;
    display: block;
    width: 720px;
    height: 470px;
    margin-right: -400px;

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .contacts__map {
    position: relative;
    margin: 0;
    margin-bottom: 60px;
    transform: translateX(calc(-50% + 368px));
  }

  .contacts__map-show {
    height: 500px;
  }

  .contacts__form.map__form {
    position: absolute;
    top: auto;
    left: auto;
    right: 50%;
    bottom: 0;
    width: 600px;
    background-color: #f6f7f8;
    transform: translateX(350px);

    & .map__form-content {
      width: 100%;
    }
  }

  .contacts__buttons-show-map {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-gap: 30px;
  }

  .contacts__button-block {
    & h3 {
      font-size: 24px;
      line-height: 30px;
    }

    & p {
      font-size: 14px;
      line-height: 20px;
    }

    & a {
      font-size: 14px;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .contacts {
    & .form {
      overflow: visible;
    }
  }

  .contacts__title {
    margin-bottom: 60px;
  }

  .contacts__tabs {
    margin-bottom: 60px;
  }

  .contacts__col {
    width: 610px;
  }

  .contacts__subtitle {
    font-size: 36px;
    line-height: 42px;
  }

  .contacts__address {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 24px;
  }

  .contacts__block {
    & .contacts__wrapper {
      min-width: 190px;
      margin: 0;
      margin-right: 90px;
      margin-bottom: 20px;

      & p {
        font-size: 16px;
        line-height: 24px;
      }
    }

    & a {
      font-size: 16px;
      line-height: 28px;
    }

    & .contacts__mail::before {
      top: 8px;
    }
  }

  .contacts__hot-line {
    font-size: 20px;
    line-height: 24px;
  }

  .contacts__image {
    width: 700px;
    height: auto;
    margin-right: -70px;
  }

  .contacts__map {
    transform: translateX(calc(-50% + 628px));
  }

  .contacts__map-show {
    height: 600px;
  }

  .contacts__form.map__form {
    width: 580px;
    transform: translateX(610px);
  }

  .contacts__buttons-show-map {
    position: relative;
    z-index: 3;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
    justify-items: flex-start;
    grid-column-gap: 80px;
    grid-row-gap: 60px;
    margin: 0;
    padding: 0;
  }

  .contacts__button-block {
    margin: 0;

    & h3 {
      font-size: 32px;
      line-height: 42px;
    }

    & p {
      font-size: 16px;
      line-height: 24px;
    }

    & a {
      font-size: 16px;
      line-height: 28px;
    }

    & .contacts__mail::before {
      top: 8px;
    }
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .contacts__tabs {
    margin-bottom: 80px;
  }

  .contacts__col {
    width: 740px;
    margin-right: 30px;
  }

  .contacts__address {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 26px;
  }

  .contacts__block {
    margin-bottom: 30px;

    & .contacts__wrapper {
      min-width: 220px;

      & p {
        font-size: 18px;
        line-height: 26px;
      }
    }

    & a {
      font-size: 18px;
      line-height: 32px;
    }

    & .contacts__phone::before {
      width: 20px;
      height: 20px;
    }

    & .contacts__mail::before {
      top: 7px;
      width: 22px;
      height: 16px;
    }
  }

  .contacts__hot-line {
    font-size: 22px;
    line-height: 26px;
  }

  .contacts__image {
    width: 1000px;
    margin-right: -100px;
  }

  .contacts__map {
    margin-bottom: 80px;
    transform: translateX(calc(-50% + 843px));
  }

  .contacts__map-show {
    height: 650px;
  }

  .contacts__form.map__form {
    transform: translateX(820px);
  }

  .contacts__button-block {
    & p {
      font-size: 18px;
      line-height: 26px;
    }

    & a {
      font-size: 18px;
      line-height: 32px;
    }

    & .contacts__phone::before {
      width: 20px;
      height: 20px;
    }

    & .contacts__mail::before {
      top: 7px;
      width: 22px;
      height: 16px;
    }
  }
}
