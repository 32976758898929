.news {
  margin-bottom: 40px;
}

.news__container {
  @include container;
  display: flex;
  flex-direction: column;
}

.news__title {
  @include title;
  margin: 0;
  margin-bottom: 25px;
}

.news__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  margin: 0;
  margin-bottom: 30px;
  padding: 0;
  list-style: none;

  & li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.news__link {
  position: relative;
  display: block;
  width: 300px;
  height: 200px;
  color: $base-color;
  text-decoration: none;

  &:hover {
    & .news__image {
      filter: grayscale(0);
      transition: 0.2s ease;

      &::before {
        opacity: 0;
        transition: 0.2s ease;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #000000;
        opacity: 0.42;
        transition: 0.2s ease;
      }
    }

    & .news__data,
    & .news__text {
      color: #ffffff;
      transition: 0.2s ease;
    }
  }
}

.news__image {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
  transition: 0.2s ease;

  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    opacity: 0.75;
    transition: 0.2s ease;
  }
}

.news__content {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 40px 30px;
}

.news__data {
  margin: 0;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  transition: 0.2s ease;

  & span {
    margin-right: 5px;
    font-weight: 700;
    font-size: 36px;
  }
}

.news__text {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  transition: 0.2s ease;
}

.news__more {
  position: relative;
  display: block;
  align-self: center;
  padding-right: 40px;
  font-size: 14px;
  line-height: 16px;
  color: $base-color;
  text-decoration: none;

  & span {
    position: absolute;
    top: -6px;
    right: 0;
    transform: rotate(180deg);
  }

  &:hover {
    & span {
      @extend .round-button:hover;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .news {
    margin-bottom: 40px;
  }

  .news__title {
    margin-bottom: 30px;
  }

  .news__list {
    justify-content: center;

    & li {
      margin-right: 10px;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  .news__link {
    width: 350px;
    height: 230px;
  }

  .news__more {
    align-self: flex-end;
    margin-right: 20px;
  }
}

@media (min-width: $breakpoint-desktop) {
  .news {
    margin-bottom: 100px;
  }

  .news__title {
    margin-bottom: 60px;
  }

  .news__list {
    justify-content: space-between;
    margin-bottom: 40px;

    & li {
      margin: 0;
    }
  }

  .news__link {
    width: 295px;
    height: 365px;
  }

  .news__image img {
    object-position: center center;
  }

  .news__more {
    padding-right: 45px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .news__link {
    width: 400px;
    height: 400px;
  }

  .news__content {
    padding: 60px;
    padding-bottom: 100px;
  }

  .news__data {
    font-size: 20px;
    line-height: 30px;

    & span {
      font-size: 48px;
    }
  }

  .news__text {
    font-size: 18px;
    line-height: 24px;
  }
}
