.doc {
  margin-bottom: 60px;
  padding-top: 115px;

  & .breadcrumbs {
    position: relative;
    z-index: 2;
  }
}

.doc__container {
  @include container;
}

.doc__content {
  margin-bottom: 60px;
}

.doc__image {
  display: block;
  width: 100vw;
  height: 200px;
  margin-left: -10px;
  margin-top: -60px;
  margin-bottom: 30px;

  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left top;
  }
}

.doc__slider {
  display: flex;
  flex-direction: column;
}

.doc__track {
  margin-bottom: 30px;
  overflow: hidden;
}

.doc__wrapper {
  display: flex;
}

.doc__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  grid-auto-rows: 60px;
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  margin: 0;
  padding: 0;
  list-style: none;

  & .documents__link {
    display: flex;
    align-items: center;
    width: auto;
    height: 60px;
    padding: 0;
    padding-left: 50px;
    text-align: left;

    &::before {
      top: 50%;
      left: 0;
      width: 40px;
      height: 52px;
      margin: 0;
      margin-top: -26px;
    }
  }
}

.doc__arrows {
  margin: 0 auto;
}

.doc__btn--next {
  margin-left: 10px;
  transform: rotate(180deg);
}

@media (min-width: $breakpoint-tablet) {
  .doc {
    margin-bottom: 100px;
    padding-top: 150px;
  }

  .doc__image {
    width: 770px;
    height: 330px;
    margin: 0;
    margin-left: -25px;
    margin-top: -70px;
    margin-bottom: 40px;

    & img {
      object-position: left center;
    }
  }

  .doc__slider {
    width: 625px;
    margin: 0 auto;
    margin-bottom: 60px;
  }

  .doc__track {
    margin-bottom: 45px;
  }

  .doc__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & li {
      width: 250px;
      margin-bottom: 40px;

      &:last-child,
      &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }

    & .documents__link {
      height: 90px;
      padding-left: 90px;

      &::before {
        width: 70px;
        height: 90px;
        margin-top: -45px;
      }
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .doc {
    & .form {
      overflow: visible;
    }
  }

  .doc__content {
    position: relative;
    z-index: 3;
    min-height: 700px;
  }

  .doc__image {
    position: absolute;
    top: -30px;
    left: 53%;
    width: 1000px;
    height: 800px;

    & img {
      object-position: center;
    }
  }

  .doc__slider {
    width: calc(50% - 10px);
    margin: 0;
    margin-right: auto;
    padding-top: 70px;
  }

  .doc__track {
    margin-bottom: 60px;
  }

  .doc__list {
    & li {
      margin-bottom: 60px;
    }
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .doc__slider {
    width: 670px;
    margin-left: 80px;
  }

  .doc__list {
    & li {
      width: 270px;
    }
  }
}
