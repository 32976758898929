.products-page {
  margin-bottom: 60px;
  padding-top: 115px;
}

.products-page__container {
  @include container;
}

.products-page__title {
  @include title;
  margin: 0;
  margin-bottom: 20px;
}

.products-page__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 165px;
  grid-gap: 10px;
  margin: 0;
  margin-bottom: 30px;
  padding: 0;
  list-style: none;
}

.products-page__item {
  border: 1px solid $gray;

  & a {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding: 25px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $base-color;
    text-decoration: none;
    transition: 0.2s ease;
    overflow: hidden;

    &:hover {
      background-color: $yellow;
      transition: 0.2s ease;
    }
  }

  & span {
    position: relative;
    display: inline-block;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -15px;
      display: block;
      width: 20px;
      height: 2px;
      background-color: $base-color;
    }

    &::after {
      content: "";
      position: absolute;
      left: 15px;
      bottom: -17px;
      display: block;
      width: 6px;
      height: 6px;
      border-right: 2px solid $base-color;
      border-bottom: 2px solid $base-color;
      transform: rotate(-45deg);
    }
  }

  & img {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: block;
    width: 150px;
    height: 100px;
    object-fit: contain;
  }
}

.products-page__text {
  margin: 0;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 19px;

  & b {
    font-weight: 700;
    font-size: 16px;
  }
}

.products-page__caption {
  margin: 20px 0 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.products-page__all-list {
  margin: 0;
  margin-bottom: 40px;
  padding: 0;
  font-size: 13px;
  line-height: 19px;
  list-style: none;
  column-count: auto;
  column-width: 300px;

  & > li {
    position: relative;
    margin-bottom: 10px;
    padding-left: 30px;
    font-weight: 600;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: "";
      position: absolute;
      left: 5px;
      top: 6px;
      display: block;
      width: 8px;
      height: 8px;
      background-color: $yellow;
      transform: rotate(45deg);
    }

    & > ul {
      margin-top: 8px;
      padding: 0;
      list-style: none;
      font-weight: 400;
    }
  }
}

.products-page__subtitle {
  margin: 0;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.4;
}

.products-page__table {
  margin-bottom: 40px;
}

@media (min-width: $breakpoint-tablet) {
  .products-page {
    margin-bottom: 100px;
    padding-top: 150px;
  }

  .products-page__list {
    grid-auto-rows: 200px;
    grid-gap: 15px;
  }

  .products-page__text {
    font-size: 14px;
    line-height: 20px;
  }

  .products-page__caption {
    font-size: 16px;
    line-height: 22px;
  }

  .products-page__all-list {
    font-size: 16px;
    line-height: 24px;
    column-count: 2;
    column-width: auto;

    & > li > ul {
      line-height: 22px;
    }
  }

  .products-page__subtitle {
    margin-bottom: 25px;
    font-size: 30px;
  }

  .products-page__table {
    margin-bottom: 60px;
  }
}

@media (min-width: $breakpoint-desktop) {
  .products-page {
    & .form {
      overflow: visible;
    }
  }

  .products-page__title {
    margin-bottom: 60px;
  }

  .products-page__list {
    grid-auto-rows: 220px;
    grid-gap: 20px;
    margin-bottom: 60px;
  }

  .products-page__item {
    & a {
      padding: 30px;
      font-size: 16px;
      line-height: 18px;
    }

    & span {
      &::before {
        width: 30px;
      }

      &::after {
        left: 25px;
      }
    }

    & img {
      right: 20px;
      bottom: 20px;
      width: 220px;
      height: 150px;
    }
  }

  .products-page__text {
    font-size: 16px;
    line-height: 24px;

    & b {
      font-size: 18px;
    }
  }

  .products-page__caption {
    margin-top: 25px;
    font-size: 18px;
    line-height: 24px;
  }

  .products-page__all-list {
    margin-bottom: 60px;
    column-count: 3;

    & > li > ul {
      line-height: 24px;
    }
  }

  .products-page__subtitle {
    margin-bottom: 25px;
    font-size: 30px;
  }

  .products-page__table {
    margin-bottom: 60px;
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .products-page__title {
    margin-bottom: 80px;
  }

  .products-page__list {
    grid-template-columns: repeat(auto-fit, 540px);
    grid-auto-rows: 300px;
    margin-bottom: 80px;
  }

  .products-page__item {
    & a {
      padding: 40px;
      font-size: 20px;
      line-height: 22px;
    }

    &.welded-flooring a::after {
      width: 244px;
      height: 158px;
    }

    &.pressed-flooring a::after {
      width: 247px;
      height: 166px;
    }

    &.gfk-flooring a::after {
      width: 242px;
      height: 149px;
    }

    &.fastener a::after {
      width: 140px;
      height: 205px;
    }

    &.steps a::after {
      width: 267px;
      height: 182px;
    }

    &.perforated-sheet a::after {
      width: 290px;
      height: 152px;
    }

    &.profiled-flooring a::after {
      width: 266px;
      height: 157px;
    }

    &.metal-fabrication a::after {
      width: 337px;
      height: 119px;
    }

    &.finished-products a::after {
      width: 242px;
      height: 159px;
    }

    &.offshore-platforms a::after {
      width: 254px;
      height: 160px;
    }

    &.anti-slip-flooring a::after {
      width: 263px;
      height: 165px;
    }

    &.shelving a::after {
      width: 266px;
      height: 180px;
    }
  }

  .products-page__text {
    font-size: 18px;
    line-height: 26px;

    & b {
      font-size: 20px;
    }
  }

  .products-page__caption {
    font-size: 22px;
    line-height: 26px;
  }

  .products-page__all-list {
    font-size: 18px;
    line-height: 26px;

    & > li > ul {
      line-height: 26px;
    }
  }
}
