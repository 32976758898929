.about {
  margin-bottom: 60px;
  padding-top: 115px;
}

.about__container {
  @include container;
}

.about__title {
  @include title;
  margin: 0;
  margin-bottom: 25px;
}

.about__links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;
  list-style: none;

  & li {
    margin: 0 15px;
    margin-bottom: 10px;
  }
}

.about__link {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $base-color;
  text-decoration: none;

  &.active,
  &:hover {
    &::after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;
      right: 0;
      height: 2px;
      background-color: $yellow;
    }
  }
}

.about__video {
  position: relative;
  display: block;
  height: 230px;
  margin-bottom: 35px;

  & picture {
    display: block;
    width: 100%;
    height: 100%;

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 0;
    height: 0;
    margin-left: -15px;
    margin-top: -20px;
    border: 20px solid $yellow;
    border-left-width: 30px;
    border-right: none;
    border-bottom-color: transparent;
    border-top-color: transparent;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.about__desc {
  margin-bottom: 40px;
  font-size: 13px;
  line-height: 19px;

  & p {
    margin: 0;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  & ul {
    margin: 15px 0;
    padding: 0;
    list-style: none;

    & li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 25px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        display: block;
        width: 10px;
        height: 10px;
        background-color: $yellow;
        transform: rotate(45deg);
      }
    }

    & a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.about__block {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 30px;
  margin-bottom: 40px;
}

.about__content {
  margin-bottom: 0;
}

.about__caption {
  margin: 0;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.about__preview {
  display: block;
  height: 200px;
  margin-bottom: 5px;
}

.about__image {
  display: block;
  width: 100%;
  height: 100%;

  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.about__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about__image-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  margin: 0;
  padding: 0;
  list-style: none;

  & li {
    &:nth-child(n + 5) {
      display: none;
    }
  }
}

.about__map {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

.about__heading {
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;

  & span {
    display: block;
    font-size: 24px;
    line-height: 28px;
  }
}

.about__total {
  margin: 40px 0 30px;
  margin-left: auto;
  font-size: 13px;
  line-height: 19px;
  text-align: right;

  & span {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }

  & b {
    display: block;
  }
}

.about__image-map {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 30px;

  & img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.about__text {
  font-size: 13px;
  line-height: 19px;

  & p {
    margin: 0;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .about {
    margin-bottom: 100px;
    padding-top: 150px;
  }

  .about__video {
    height: 400px;
  }

  .about__desc {
    margin-bottom: 60px;
    font-size: 14px;
    line-height: 20px;
  }

  .about__block {
    display: block;
    margin-bottom: 60px;
  }

  .about__content {
    margin-bottom: 25px;
  }

  .about__caption {
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 28px;
  }

  .about__preview {
    height: 460px;
    margin-bottom: 10px;
  }

  .about__image-list {
    grid-gap: 10px;
    grid-auto-rows: 120px;
  }

  .about__heading {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 28px;

    & span {
      font-size: 36px;
      line-height: 40px;
    }
  }

  .about__total {
    margin: 0;
    margin-left: auto;
    margin-right: 90px;
    font-size: 14px;
    line-height: 20px;
    text-align: left;

    & span {
      font-size: 36px;
      line-height: 40px;
    }
  }

  .about__text {
    padding: 0 60px;
    font-size: 14px;
    line-height: 20px;
  }
}

@media (min-width: $breakpoint-desktop) {
  .about {
    & .form {
      overflow: visible;
    }
  }

  .about__title {
    margin-bottom: 60px;
  }

  .about__links {
    margin-bottom: 35px;
  }

  .about__link {
    margin: 0 20px;
    font-size: 18px;
    line-height: 24px;
  }

  .about__video {
    margin-bottom: 60px;
  }

  .about__desc {
    column-count: 2;
    column-gap: 80px;
    font-size: 16px;
    line-height: 24px;

    & p {
      margin-bottom: 20px;
    }

    & ul li::before {
      top: 6px;
    }
  }

  .about__block {
    display: flex;
    justify-content: space-between;

    &:nth-of-type(even) {
      flex-direction: row-reverse;

      & .about__content {
        padding-left: 35px;
      }
    }
  }

  .about__content {
    width: calc(50% - 10px);
    column-count: 1;
    padding-top: 50px;
    padding-right: 35px;
  }

  .about__gallery {
    width: calc(50% - 10px);
  }

  .about__map {
    margin-top: 100px;
    margin-bottom: 0;
  }

  .about__heading {
    margin: 0;
    margin-right: 150px;
    font-size: 36px;
    line-height: 40px;

    & span {
      font-size: 72px;
      line-height: 76px;
    }
  }

  .about__total {
    margin: 0;
    font-size: 18px;
    line-height: 24px;

    & span {
      font-size: 60px;
      line-height: 64px;
    }

    & b {
      margin-left: -10px;
    }
  }

  .about__image-map {
    margin-bottom: 40px;
  }

  .about__text {
    width: 50%;
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .about__title {
    margin-bottom: 80px;
  }

  .about__link {
    font-size: 20px;
    line-height: 24px;
  }

  .about__video {
    margin-bottom: 80px;
  }

  .about__desc {
    font-size: 18px;
    line-height: 26px;
  }

  .about__caption {
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 32px;
  }

  .about__preview {
    height: 500px;
  }

  .about__image-list {
    grid-auto-rows: 150px;
  }

  .about__heading {
    margin-right: 0;
  }

  .about__total {
    margin: 0 auto;
  }

  .about__text {
    font-size: 18px;
    line-height: 26px;
  }
}
