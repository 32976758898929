.info {
  position: relative;
  padding-top: 90px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 74px;
    height: 74px;
    margin-left: -37px;
    background: url("../images/png/info.png") no-repeat center/contain;
  }

  & h2 {
    margin: 0;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
  }

  & p {
    margin: 0;
    font-size: 13px;
    line-height: 19px;
  }
}

@media (min-width: $breakpoint-tablet) {
  .info {
    padding: 0;
    padding-left: 115px;

    &::before {
      top: 10px;
      left: 0;
      width: 86px;
      height: 86px;
      margin: 0;
    }

    & h2 {
      font-size: 20px;
      line-height: 22px;
      text-align: left;
    }

    & p {
      font-size: 14px;
      line-height: 22px;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .info {
    padding-left: 140px;

    &::before {
      width: 100px;
      height: 100px;
    }

    & h2 {
      font-size: 30px;
      line-height: 32px;
    }

    & p {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
