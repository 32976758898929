.table__wrap {
  position: relative;
  z-index: 2;
  overflow-x: auto;
}

.table {
  table-layout: fixed;
  border: 1px solid $gray;
  border-collapse: collapse;

  & th,
  & td {
    min-width: 120px;
    padding: 10px;
    border: 1px solid $gray;
  }

  & th {
    @include text;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  & td {
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    vertical-align: middle;

    &:first-child {
      text-align: left;
    }
  }
}

.table__button {
  padding: 8px 15px;
  font-size: 13px;
  line-height: 13px;
}

.table__fill {
  background-color: $yellow;
}

.table__name {
  font-weight: 500;
}

.table__image {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.table__caption {
  font-weight: 500;
}

.table__text-center {
  text-align: center !important;
}

@media (min-width: $breakpoint-tablet) {
  .table {
    & th,
    & td {
      min-width: 150px;
      padding: 15px;

      &:first-child {
        min-width: 200px;
      }
    }

    & th {
      font-size: 16px;
      line-height: 1.4;
    }

    & td {
      font-size: 14px;
      line-height: 1.4;
    }
  }

  .table__button {
    padding: 8px 15px;
    font-size: 14px;
    line-height: 14px;
  }
}

@media (min-width: $breakpoint-desktop) {
  .table {
    table-layout: auto;
    width: 100%;

    &--size-m {
      width: auto;
    }

    & th,
    & td {
      min-width: 10%;
      padding: 20px;

      &:first-child {
        min-width: 250px;
      }
    }

    & th {
      font-size: 18px;
    }

    & td {
      font-size: 16px;
    }
  }

  .table__button {
    padding: 8px 25px;
    font-size: 16px;
    line-height: 16px;
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .table {
    & th,
    & td {
      padding: 20px 25px;
    }

    & td {
      font-size: 18px;
    }
  }

  .table__button {
    padding: 10px 30px;
  }
}
