.map {
  display: flex;
  flex-direction: column-reverse;
  color: #ffffff;
  background-color: $brown;
}

.map__map {
  height: 190px;
  background: url("../images/jpg/map-mobile.jpg") no-repeat center/cover;
}

.map__container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 40px;
}

.map__title {
  @include title;
  margin: 0;
  margin-bottom: 25px;
}

.map__tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 15px;
}

.map__btn {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #ffffff;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid transparent;
  outline: none;

  &.active {
    border-color: $yellow;
  }
}

.map__content {
  display: none;

  &.active {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    margin-bottom: 30px;
  }
}

.map__hot-line {
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  & a {
    margin-left: 5px;
    color: #ffffff;
    text-decoration: none;
  }
}

.map__wrapper {
  margin-bottom: 15px;
  padding: 0 20px;
  font-size: 13px;
  line-height: 21px;

  &:last-of-type {
    margin-bottom: 0;
  }

  & p {
    margin: 0;
    font-weight: 500;
  }
}

.map__phone {
  position: relative;
  display: block;
  padding-left: 30px;
  line-height: 24px;
  color: #ffffff;
  text-decoration: none;

  &::before {
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background: url("../images/png/phone-white.png") no-repeat center/contain;
  }
}

.map__mail {
  position: relative;
  display: block;
  padding-left: 30px;
  line-height: 24px;
  color: #ffffff;
  text-decoration: none;

  &::before {
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    display: block;
    width: 18px;
    height: 12px;
    background: url("../images/png/mail-white.png") no-repeat center/contain;
  }
}

.map__form {
  order: -1;
  width: 100vw;
  margin-top: -20px;
  margin-left: -20px;
  margin-bottom: 20px;
  padding: 10px;
  padding-bottom: 25px;
  color: $base-color;
  background-color: #ffffff;
}

.map__form-field {
  display: flex;
  flex-direction: column;
}

.map__caption {
  align-self: flex-end;
  margin: 0;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
}

.map__name {
  align-self: flex-end;
  margin: 0;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 15px;
}

.map__form-btn {
  margin-bottom: 5px;
}

.map__input {
  & input {
    width: 100%;
    font-size: 13px;
    line-height: 19px;
    background-color: transparent;
    text-align: right;
    border: none;
    border-bottom: 1px solid $base-color;
  }
}

.map__form-image {
  display: none;
}

@media (min-width: $breakpoint-tablet) {
  .map__container {
    position: relative;
    width: 720px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    padding-top: 170px;
  }

  .map__title {
    text-align: left;
  }

  .map__tabs {
    justify-content: flex-start;
  }

  .map__btn {
    margin-right: 10px;
    font-size: 14px;
    line-height: 22px;
  }

  .map__content {
    &.active {
      justify-content: flex-start;
    }
  }

  .map__wrapper {
    width: 50%;
    padding: 0;
    font-size: 14px;
    line-height: 24px;

    &:first-of-type {
      width: 100%;
    }

    & b {
      font-size: 16px;
      line-height: 26px;
    }
  }

  .map__hot-line {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }

  .map__form {
    position: absolute;
    top: 0;
    bottom: auto;
    left: calc(50% - 50vw);
    z-index: 2;
    width: 100vw;
    margin: 0;
    padding: 0;
  }

  .map__form-content {
    display: flex;
    justify-content: center;
    width: 720px;
    margin: 0 auto;
  }

  .map__form-field {
    flex-grow: 1;
    padding: 20px;
  }

  .map__caption {
    font-size: 18px;
    line-height: 22px;
  }

  .map__name {
    margin-bottom: auto;
    font-size: 14px;
    line-height: 16px;
  }

  .map__form-btn {
    margin-bottom: -30px;
  }

  .map__input {
    margin-left: 50px;

    & input {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .map__form-image {
    display: block;
    width: 150px;
    height: 150px;
    object-fit: cover;
    object-position: center top;
  }

  .map__map {
    height: 260px;
    background-image: url("../images/jpg/map-tablet.jpg");
  }
}

@media (min-width: $breakpoint-desktop) {
  .map {
    flex-direction: row;
    justify-content: center;
    min-height: 500px;
  }

  .map__container {
    width: 630px;
    margin: 0;
    margin-left: calc(50% - 620px);
    padding: 90px 70px 70px 0;
  }

  .map__title {
    margin-bottom: 50px;
  }

  .map__tabs {
    margin-bottom: 50px;
  }

  .map__btn {
    font-size: 16px;
    line-height: 24px;
  }

  .map__wrapper {
    font-size: 16px;
    line-height: 26px;

    & b {
      font-size: 18px;
    }
  }

  .map__hot-line {
    margin-top: auto;
    font-size: 20px;
    line-height: 22px;
  }

  .map__form {
    top: auto;
    bottom: 0;
    right: auto;
    left: 100%;
    width: 600px;
  }

  .map__form-content {
    width: 100%;
  }

  .map__map {
    flex-grow: 1;
    height: auto;
    min-height: 100%;
    background-image: url("../images/jpg/map-desktop.jpg");
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .map {
    min-height: 600px;
  }

  .map__container {
    width: 740px;
    margin-left: calc(50% - 835px);
    padding-right: 120px;
  }
}
