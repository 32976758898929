.technologie {
  margin-bottom: 60px;
  padding-top: 115px;

  @media (min-width: 768px) {
    margin-bottom: 100px;
    padding-top: 150px;
  }
}

.technologie__container {
  padding: 0 10px;

  @media (min-width: 768px) {
    width: 720px;
    margin: 0 auto;
    padding: 0;
  }

  @media (min-width: 1280px) {
    width: 1240px;
  }

  @media (min-width: 1700px) {
    width: 1670px;
  }
}

.technologie__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 30px;

  @media (min-width: 1280px) {
    grid-template-columns: repeat(5, 225px);
    grid-auto-rows: 240px;
    grid-auto-flow: dense;
    grid-gap: 28px;
  }

  @media (min-width: 1700px) {
    grid-template-columns: repeat(5, 310px);
    grid-auto-rows: 310px;
    grid-gap: 30px;
  }
}

.technologie__heading {
  grid-row: 1;

  @media (orientation: landscape) {
    grid-column: 1 / -1;
  }

  @media (min-width: 768px) {
    grid-column: 1 / -1;
  }

  @media (min-width: 1280px) {
    grid-column: 1;
    grid-row: 1 / span 2;
  }

  @media (min-width: 1700px) {
    grid-column: 1;
    grid-row: 2 / span 2;
  }
}

.technologie__title {
  margin: 0 0 25px;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;

  @media (min-width: 768px) {
    margin-bottom: 30px;
    font-size: 30px;
    line-height: 36px;
  }

  @media (min-width: 1280px) {
    font-size: 32px;
    line-height: 1;
  }

  @media (min-width: 1700px) {
    font-size: 44px;
  }
}

.technologie__list {
  margin: 0;
  padding-left: 25px;

  & li:not(:last-child) {
    margin-bottom: 5px;
  }

  @media (orientation: landscape) {
    column-count: 2;
    column-gap: 40px;
  }

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 1.3;
    column-count: 2;
    column-gap: 40px;

    & li:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  @media (min-width: 1280px) {
    column-count: auto;
  }

  @media (min-width: 1700px) {
    font-size: 19px;

    & li:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.technologie__item {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;

  & a {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid $gray;

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  & figcaption {
    min-height: 40px;
    font-size: 16px;
    text-align: center;
  }

  @media (min-width: 768px) {
    & figcaption {
      min-height: 50px;
      font-size: 18px;
      line-height: 1.3;
    }
  }

  @media (min-width: 1280px) {
    & figcaption {
      min-height: 40px;
      font-size: 14px;
    }

    &[data-label="1"]:hover ~ .technologie__heading li[data-hover="1"] {
      color: $yellow;
    }

    &[data-label="2"]:hover ~ .technologie__heading li[data-hover="2"] {
      color: $yellow;
    }

    &[data-label="3"]:hover ~ .technologie__heading li[data-hover="3"] {
      color: $yellow;
    }

    &[data-label="4"]:hover ~ .technologie__heading li[data-hover="4"] {
      color: $yellow;
    }

    &[data-label="5"]:hover ~ .technologie__heading li[data-hover="5"] {
      color: $yellow;
    }

    &[data-label="6"]:hover ~ .technologie__heading li[data-hover="6"] {
      color: $yellow;
    }

    &[data-label="7"]:hover ~ .technologie__heading li[data-hover="7"] {
      color: $yellow;
    }

    &[data-label="8"]:hover ~ .technologie__heading li[data-hover="8"] {
      color: $yellow;
    }

    &[data-label="9"]:hover ~ .technologie__heading li[data-hover="9"] {
      color: $yellow;
    }

    &[data-label="10"]:hover ~ .technologie__heading li[data-hover="10"] {
      color: $yellow;
    }
  }

  @media (min-width: 1280px) {
    &.size-l {
      grid-column: 3 / span 2;
      grid-row: span 2;
    }
  }

  @media (min-width: 1700px) {
    & figcaption {
      font-size: 18px;
    }
  }
}
