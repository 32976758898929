.service__faq-wrap {
  margin-bottom: 30px;
}

.service__faq {
  display: flex;
  flex-direction: column;
  border: 1px solid $light-gray;
}

.service__caption {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 65px;
  margin: 0;
  padding: 12px;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  background-color: $yellow;
  text-align: center;
}

.service__wrapper {
  padding: 25px 20px;
  overflow: hidden;
  transition: all 0.3s ease;

  & p {
    margin: 0;
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 19px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  & ul {
    margin: 0;
    padding: 0;
    list-style: none;

    & li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 10px;
      font-size: 13px;
      line-height: 19px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: "";
        position: absolute;
        top: 6px;
        left: -9px;
        display: block;
        width: 8px;
        height: 8px;
        background-color: $yellow;
        transform: rotate(45deg);
      }
    }
  }

  & > p:nth-of-type(2n+1) {
    display: none;
  }

  & > ul li:nth-child(2n+1) {
    display: none;
  }

  &.show {
    & > p:nth-of-type(2n+1) {
      display: block;
    }

    & > ul li:nth-child(2n+1) {
      display: block;
    }

    & + .service__show-more {
      display: none;
    }
  }
}

.service__show-more {
  position: relative;
  display: block;
  width: 25px;
  height: 20px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 0;
  background-color: transparent;
  border: none;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 5px;
    display: block;
    width: 15px;
    height: 15px;
    border-left: 1px solid #000000;
    border-bottom: 1px solid #000000;
    transform: rotate(-45deg);
  }

  &::after {
    top: -5px;
  }

  &:hover {
    &::before,
    &::after {
      border-color: $yellow;
    }
  }
}

.service__info {
  margin-bottom: 30px;
}

.service__gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 250px;
  grid-gap: 10px;
  margin: 0;
  margin-bottom: 60px;
  padding: 0;
  list-style: none;
}

.service__image {
  display: block;
  width: 100%;
  height: 100%;

  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (min-width: $breakpoint-tablet) {
  .service__faq-wrap {
    margin-bottom: 60px;
  }

  .service__caption {
    min-height: 80px;
    font-size: 20px;
    line-height: 24px;
  }

  .service__wrapper {
    padding: 50px 40px 40px;

    & p,
    & ul li {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .service__info {
    margin-bottom: 60px;
  }

  .service__gallery {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 200px;
  }

  .service__show-more {
    margin-bottom: 0;
    transform: translateY(-20px);
  }
}

@media (min-width: $breakpoint-desktop) {
  .service__faq-wrap {
    display: flex;
  }

  .service__faq {
    width: calc(100% / 3);
  }

  .service__info {
    margin-bottom: 80px;
  }

  .service__gallery {
    grid-auto-rows: 330px;
    grid-gap: 20px;
    margin-bottom: 0;
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .service__gallery {
    grid-auto-rows: 440px;
  }
}
