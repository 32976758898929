.features {
  margin-bottom: 30px;
}

.features__container {
  @include container;
}

.features__title {
  @include title;
  margin: 0;
  margin-bottom: 20px;
}

.features__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;
  list-style: none;
}

.features__item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 230px;
  height: 160px;
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 30px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  border: 1px solid $gray;

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 50%;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-child(1)::before {
    top: 18px;
    width: 88px;
    height: 64px;
    margin-left: -44px;
    background-image: url("../images/svg/features-1.svg");
  }

  &:nth-child(2)::before {
    top: 21px;
    width: 118px;
    height: 65px;
    margin-left: -50px;
    background-image: url("../images/svg/features-2.svg");
  }

  &:nth-child(3)::before {
    width: 56px;
    height: 71px;
    margin-left: -28px;
    background-image: url("../images/png/features-3.png");
  }

  &:nth-child(4)::before {
    width: 58px;
    height: 78px;
    margin-left: -29px;
    background-image: url("../images/svg/features-4.svg");
  }

  &:nth-child(5)::before {
    width: 58px;
    height: 73px;
    margin-left: -29px;
    background-image: url("../images/png/features-5.png");
  }
}

.features__wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.features__text {
  margin: 0;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  opacity: 0.9;

  & b {
    display: block;
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    opacity: 0.84;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

@media (min-width: $breakpoint-tablet) {
  .features {
    margin-bottom: 40px;
  }

  .features__title {
    margin-bottom: 30px;
  }

  .features__list {
    justify-content: center;
    margin-bottom: 35px;
  }

  .features__item {
    width: 230px;
    height: 200px;
    margin: 0;
    margin-right: 15px;
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 18px;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(1)::before {
      width: 112px;
      height: 82px;
      margin-left: -52px;
      top: 25px;
    }

    &:nth-child(2)::before {
      width: 134px;
      height: 75px;
      margin-left: -58px;
      top: 35px;
    }

    &:nth-child(3)::before {
      width: 68px;
      height: 88px;
      margin-left: -34px;
      top: 25px;
    }

    &:nth-child(4)::before {
      width: 74px;
      height: 100px;
      margin-left: -37px;
      top: 15px;
    }

    &:nth-child(5)::before {
      width: 66px;
      height: 95px;
      margin-left: -33px;
      top: 20px;
    }
  }

  .features__wrap {
    align-items: flex-end;
    justify-content: space-between;
  }

  .features__text {
    margin: 0;
    font-size: 14px;
    line-height: 18px;

    & span {
      font-size: 50px;
      line-height: 56px;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .features {
    margin: 0;
    padding-top: 80px;
    padding-bottom: 100px;
    overflow: hidden;
  }

  .features__container {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -752px;
      left: -1033px;
      display: block;
      width: 1315px;
      height: 1496px;
      background: url("../images/svg/decor.svg") no-repeat center/contain;
      opacity: 0.7;
    }

    &::after {
      content: '';
      position: absolute;
      top: -420px;
      right: -1120px;
      display: block;
      width: 1315px;
      height: 1496px;
      background: url("../images/svg/decor.svg") no-repeat center/contain;
      transform: scaleX(-1);
      filter: "FlipH";
      opacity: 0.7;
    }
  }

  .features__title {
    margin-bottom: 90px;
  }

  .features__list {
    position: relative;
    z-index: 1;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 60px;
  }

  .features__item {
    width: 235px;
    height: 235px;
    margin: 0;
    padding: 45px 30px;
    font-size: 16px;
    line-height: 20px;
    background-color: #ffffff;

    &:nth-child(1)::before {
      top: 35px;
    }

    &:nth-child(2)::before {
      top: 45px;
    }

    &:nth-child(3)::before {
      top: 35px;
    }

    &:nth-child(4)::before {
      top: 30px;
    }

    &:nth-child(5)::before {
      top: 35px;
    }
  }

  .features__wrap {
    padding: 0 50px;
  }

  .features__text {
    font-size: 20px;
    line-height: 24px;

    & b {
      font-size: 80px;
      line-height: 90px;
    }
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .features {
    padding-top: 120px;
    padding-bottom: 130px;
    overflow: visible;
  }

  .features__container {
    &::before {
      left: -973px;
      top: -745px;
    }

    &::after {
      right: -1094px;
      top: -215px;
    }
  }

  .features__item {
    width: 305px;
    height: 250px;
    padding-bottom: 20px;
    font-size: 18px;
    line-height: 24px;

    &:nth-child(1)::before {
      top: 45px;
      width: 145px;
      height: 107px;
      margin-left: -70px;
    }

    &:nth-child(2)::before {
      width: 190px;
      height: 107px;
      margin-left: -85px;
    }

    &:nth-child(3)::before {
      top: 15px;
      width: 111px;
      height: 144px;
      margin-left: -55px;
    }

    &:nth-child(4)::before {
      top: 20px;
      width: 101px;
      height: 138px;
      margin-left: -50px;
    }

    &:nth-child(5)::before {
      top: 30px;
      width: 88px;
      height: 128px;
      margin-left: -44px;
    }
  }

  .features__wrap {
    justify-content: center;
  }

  .features__text {
    margin-right: 120px;

    &:last-of-type {
      margin-right: 0;
    }

    & b {
      font-size: 90px;
      line-height: 100px;
    }
  }
}
