.footer {
  padding: 30px 0;
  background-color: #f4f4f4;
}

.footer__container {
  @include container;
  display: flex;
  flex-direction: column-reverse;
}

.footer__btn-scroll-up {
  display: none;
}

.footer__copyright {
  & p {
    margin: 0;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }
}

.footer__social-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.footer__social-btn {
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 10px;

  & svg {
    width: 30px;
    height: 30px;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    & svg {
      fill: $yellow;
    }
  }
}

.footer__logo {
  display: none;
}

.footer__menu {
  margin-bottom: 30px;
}

.footer__catalog {
  margin-bottom: 25px;
}

.footer__caption {
  margin: 0;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.footer__list {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.footer__link {
  position: relative;
  line-height: 21px;
  color: $base-color;
  text-decoration: none;

  &:hover {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -2px;
      height: 1px;
      background-color: $yellow;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .footer__container {
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .footer__btn-scroll-up {
    position: absolute;
    top: 0;
    right: -20px;
    display: block;
    width: 120px;
    padding: 10px 20px;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    background-color: #ffffff;
    text-decoration: none;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    transform: rotate(-90deg);

    &::before {
      content: "";
      position: absolute;
      top: 15px;
      right: 25px;
      display: block;
      width: 8px;
      height: 8px;
      border-right: 2px solid #000000;
      border-bottom: 2px solid #000000;
      transform: rotate(-45deg);
    }
  }

  .footer__copyright {
    display: flex;
    flex-direction: column;
    align-self: stretch;

    & p {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .footer__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 167px;
    height: 119px;
    margin-bottom: 20px;

    & img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .footer__catalog {
    order: -2;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    margin-bottom: 40px;
    margin-right: auto;
  }

  .footer__caption {
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
  }

  .footer__list {
    width: 50%;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
  }

  .footer__menu {
    order: -1;
    width: 50%;
    margin: 0;

    & .footer__list {
      width: 100%;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .footer {
    padding: 50px 0;
  }

  .footer__container {
    justify-content: space-between;
  }

  .footer__btn-scroll-up {
    width: 130px;
    font-size: 18px;
    line-height: 18px;
  }

  .footer__menu {
    order: 0;
    width: auto;
    margin: 0;
  }

  .footer__list {
    font-size: 16px;
    line-height: 26px;
  }

  .footer__caption {
    font-size: 18px;
  }

  .footer__catalog {
    order: 0;
    width: auto;
    margin: 0;
    margin-right: 70px;
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .footer__catalog {
    justify-content: space-between;
    width: 50%;
    margin-right: 0;
  }
}
