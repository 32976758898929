.documents {
  margin-bottom: 25px;
  padding-top: 25px;
  padding-bottom: 35px;
  background-color: $light-gray;
}

.documents__container {
  @include container;
  display: flex;
  flex-direction: column;
}

.documents__title {
  @include title;
  margin: 0;
  margin-bottom: 25px;
}

.documents__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0;
  margin-bottom: 10px;
  padding: 0 20px;
  list-style: none;

  & li {
    margin-bottom: 25px;
  }
}

.documents__link {
  position: relative;
  display: block;
  width: 120px;
  padding-top: 70px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: $base-color;
  text-align: center;
  text-decoration: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 54px;
    height: 59px;
    margin-left: -27px;
    background: url("../images/png/pdf.png") no-repeat center/contain;
  }

  &:hover {
    &:before {
      background-image: url("../images/png/pdf-hover.png");
    }
  }
}

.documents__more {
  position: relative;
  display: block;
  align-self: center;
  padding-right: 40px;
  font-size: 14px;
  line-height: 16px;
  color: $base-color;
  text-decoration: none;

  & span {
    position: absolute;
    top: -6px;
    right: 0;
    transform: rotate(180deg);
  }

  &:hover {
    & span {
      @extend .round-button:hover;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .documents {
    margin-bottom: 40px;
    padding: 40px 0;
  }

  .documents__title {
    margin-bottom: 30px;
  }

  .documents__list {
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 0 60px;
  }

  .documents__link {
    display: flex;
    align-items: center;
    width: 220px;
    height: 100px;
    padding: 0;
    padding-left: 95px;
    font-size: 18px;
    line-height: 22px;
    text-align: left;

    &::before {
      width: 72px;
      height: 94px;
      left: 0;
      top: 50%;
      margin: 0;
      margin-top: -47px;
    }

    &:hover {
      text-decoration: underline solid $yellow;
    }
  }

  .documents__more {
    align-self: flex-end;
    margin-right: 10px;
    font-size: 18px;
    line-height: 22px;

    & span {
      top: -4px;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .documents {
    margin-bottom: 0;
    padding-top: 70px;
    padding-bottom: 60px;
  }

  .documents__title {
    margin-bottom: 60px;
  }

  .documents__list {
    margin-bottom: 40px;
  }

  .documents__more {
    margin-right: 40px;
    padding-right: 45px;

    & span {
      top: -6px;
    }
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .documents {
    padding-top: 80px;
    padding-bottom: 100px;
  }

  .documents__container {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .documents__title {
    width: 100%;
  }

  .documents__list {
    flex-grow: 1;
    margin: 0;
    margin-right: 80px;
    padding: 0;

    & li {
      margin: 0;
    }
  }

  .documents__more {
    align-self: center;
  }
}
