@keyframes bounce {
  0% {
    transform: translateY(-2000px);
  }

  70% {
    transform: translateY(30px);
  }

  90% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.popup {
  position: fixed;
  top: 10%;
  left: 50%;
  z-index: 2001;
  display: none;
  width: 300px;
  margin-left: -150px;
  padding: 20px 15px 30px;
  background-color: #ffffff;

  &.show {
    display: block;
    animation: bounce 0.6s;
  }
}


.popup__btn-close {
  position: absolute;
  top: 5px;
  right: 5px;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 9px;
    left: 2px;
    display: block;
    width: 15px;
    height: 2px;
    background-color: $yellow;
    border-radius: 3px;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.popup__title {
  margin: 0;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.4;
  text-align: center;
}

.popup__content {
  display: flex;
  flex-direction: column;
}

@media (min-width: $breakpoint-tablet) {
  .popup {
    top: 15%;
    width: 560px;
    margin-left: -280px;
    padding: 30px 60px;
  }

  .popup__btn-close {
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;

    &::before,
    &::after {
      top: 14px;
      left: 0;
      width: 30px;
    }
  }

  .popup__title {
    margin-bottom: 30px;
    font-size: 24px;
  }
}

@media (min-width: $breakpoint-desktop) {
  .popup {
    width: 750px;
    margin-left: -375px;
    padding: 45px 60px 50px;
  }

  .popup__btn-close {
    top: 15px;
    right: 15px;
  }

  .popup__title {
    margin-bottom: 40px;
    font-size: 30px;
  }
}
