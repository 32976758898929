.services {
  margin-bottom: 60px;
  padding-top: 115px;
}

.services__container {
  @include container;
}

.services__title {
  @include title;
  margin: 0;
  margin-bottom: 25px;
}

.services__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 200px;
  grid-auto-flow: dense;
  grid-gap: 10px;
  margin: 0;
  margin-bottom: 60px;
  padding: 0;
  list-style: none;
}

.services__link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  color: #ffffff;
  text-decoration: none;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(45deg, #ffffff 15%, $yellow);
    opacity: 0;
    transition: all 0.3s ease;
  }

  &:hover {
    color: #000000;

    &::before {
      opacity: 0.8;
      transition: all 0.3s ease;
    }
  }
}

.services__image {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.7), transparent);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #000000;
    opacity: 0.2;
  }
}

.services__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  padding: 25px 20px;
}

.services__name {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: $breakpoint-tablet) {
  .services {
    margin-bottom: 100px;
    padding-top: 150px;
  }

  .services__list {
    grid-auto-rows: 235px;
    grid-gap: 15px;

    & li.size-m {
      grid-column: span 2;
    }
  }

  .services__content {
    padding: 35px 30px;
  }

  .services__name {
    font-size: 18px;
    line-height: 24px;
  }
}

@media (min-width: $breakpoint-desktop) {
  .services {
    & .form {
      overflow: visible;
    }
  }

  .services__title {
    margin-bottom: 60px;
  }

  .services__list {
    position: relative;
    z-index: 3;
    grid-auto-rows: 300px;
    grid-gap: 20px;
    margin-bottom: 0;

    & li.size-m {
      grid-column: span 1;
      grid-row: span 2;
    }
  }

  .services__content {
    padding: 40px;
  }

  .services__name {
    font-size: 20px;
    line-height: 26px;
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .services__title {
    margin-bottom: 80px;
  }

  .services__list {
    grid-template-columns: repeat(auto-fit, minmax(540px, 1fr));
    grid-auto-rows: 360px;
  }

  .services__content {
    padding: 60px;
  }

  .services__name {
    font-size: 24px;
    line-height: 30px;
  }
}
