.app-area {
  margin-bottom: 60px;
  padding-top: 115px;
}

.app-area__container {
  @include container;
}

.app-area__title {
  @include title;
  margin: 0;
  margin-bottom: 50px;
}

.app-area__content {
  margin-bottom: 60px;
}

.app-area__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.app-area__item {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  &.chemistry .app-area__caption::after {
    content: "";
    position: absolute;
    top: -20px;
    left: 10px;
    display: block;
    width: 37px;
    height: 58px;
    background: url("../images/svg/chemistry.svg") no-repeat center/contain;
  }

  &.oil .app-area__caption::after {
    content: "";
    position: absolute;
    top: -8px;
    left: 3px;
    display: block;
    width: 56px;
    height: 39px;
    background: url("../images/svg/oil.svg") no-repeat center/contain;
  }

  &.logistics .app-area__caption::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 5px;
    display: block;
    width: 56px;
    height: 43px;
    background: url("../images/svg/logistic.svg") no-repeat center/contain;
  }

  &.citizens .app-area__caption::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 5px;
    display: block;
    width: 57px;
    height: 41px;
    background: url("../images/svg/citizens.svg") no-repeat center/contain;
  }
}

.app-area__caption {
  position: relative;
  margin: 0;
  margin-bottom: 15px;
  padding-left: 120px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #000000;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 75px;
    width: 30px;
    height: 2px;
    margin-top: -1px;
    background-color: #000000;
  }

  &.active {
    &::before {
      background-color: $yellow;
    }

    & + .app-area__sublist {
      display: block;
    }
  }
}

.app-area__sublist {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

.app-area__subitem {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  & span {
    position: relative;
    display: block;
    padding-left: 120px;
    font-size: 13px;
    line-height: 17px;
    color: #000000;

    &.active {
      margin-bottom: 20px;

      &::before {
        content: "";
        position: absolute;
        top: 4px;
        left: 100px;
        display: block;
        width: 10px;
        height: 10px;
        background-color: $yellow;
        transform: rotate(45deg);
      }

      & + .app-area__wrapper {
        display: block;
      }
    }
  }
}

.app-area__wrapper {
  display: none;
}

.app-area__gallery {
  margin-bottom: 20px;
}

.app-area__big-img {
  display: block;
  height: 290px;
  margin-bottom: 10px;

  & picture {
    display: block;
    width: 100%;
    height: 100%;

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.app-area__image-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;

  & li {
    width: calc(50% - 5px);
    height: 125px;
    margin-bottom: 10px;

    &:last-child,
    &:nth-last-child(2) {
      margin-bottom: 0;
    }
  }

  & a {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.app-area__image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: $breakpoint-tablet) {
  .app-area {
    margin-bottom: 100px;
    padding-top: 150px;
  }

  .app-area__content {
    margin-bottom: 100px;
  }

  .app-area__item {
    margin-bottom: 45px;

    &.chemistry .app-area__caption::after {
      top: -45px;
      left: 105px;
      width: 49px;
      height: 77px;
    }

    &.oil .app-area__caption::after {
      top: -25px;
      left: 90px;
      width: 77px;
      height: 53px;
    }

    &.logistics .app-area__caption::after {
      top: -30px;
      left: 95px;
      width: 77px;
      height: 59px;
    }

    &.citizens .app-area__caption::after {
      top: -30px;
      left: 100px;
      width: 69px;
      height: 55px;
    }
  }

  .app-area__caption {
    padding-left: 300px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 20px;

    &::before {
      left: 210px;
      width: 55px;
    }
  }

  .app-area__subitem {
    margin-bottom: 15px;

    & span {
      padding-left: 320px;
      font-size: 14px;
      line-height: 18px;

      &.active {
        margin-bottom: 30px;

        &::before {
          left: 300px;
        }
      }
    }
  }

  .app-area__big-img {
    height: 480px;
  }

  .app-area__image-list {
    & li {
      width: 170px;
      height: 150px;
      margin: 0;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .app-area {
    & .form {
      overflow: visible;
    }
  }

  .app-area__title {
    width: 420px;
    text-align: left;
  }

  .app-area__content {
    position: relative;
    z-index: 3;
    margin: 0;
  }

  .app-area__list {
    width: 525px;
  }

  .app-area__item {
    margin-bottom: 60px;

    &.chemistry .app-area__caption::after {
      top: -30px;
      left: 10px;
      width: 40px;
      height: 63px;
    }

    &.oil .app-area__caption::after {
      top: -20px;
      left: 0;
      width: 72px;
      height: 50px;
    }

    &.logistics .app-area__caption::after {
      top: -20px;
      left: 0;
      width: 68px;
      height: 52px;
    }

    &.citizens .app-area__caption::after {
      top: -20px;
      left: 0;
      width: 62px;
      height: 45px;
    }
  }

  .app-area__caption {
    padding-left: 165px;
    font-size: 20px;
    line-height: 24px;
    cursor: pointer;

    &::before {
      left: 100px;
      width: 40px;
    }
  }

  .app-area__subitem {
    & span {
      padding-left: 190px;
      font-size: 16px;
      line-height: 20px;
      cursor: pointer;

      &.active {
        margin-bottom: 0;

        &::before {
          left: 170px;
        }
      }
    }
  }

  .app-area__wrapper {
    position: absolute;
    top: -255px;
    right: 0;
    width: 700px;
  }

  .app-area__big-img {
    height: 620px;
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .app-area__title {
    margin-bottom: 80px;
  }

  .app-area__list {
    width: 710px;
    padding-left: 30px;
  }

  .app-area__item {
    &.chemistry .app-area__caption::after {
      top: -45px;
      width: 55px;
      height: 86px;
    }

    &.oil .app-area__caption::after {
      top: -20px;
      left: -8px;
      width: 86px;
      height: 59px;
    }

    &.logistics .app-area__caption::after {
      top: -30px;
      left: -3px;
      width: 85px;
      height: 65px;
    }

    &.citizens .app-area__caption::after {
      top: -30px;
      width: 76px;
      height: 55px;
    }
  }

  .app-area__caption {
    font-size: 22px;
    line-height: 26px;
  }

  .app-area__subitem {
    & span {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .app-area__wrapper {
    top: -285px;
    width: 890px;
  }

  .app-area__image-list {
    & li {
      width: 215px;
      height: 180px;
    }
  }
}
