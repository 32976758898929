@mixin text() {
  hyphens: auto;
  word-wrap: break-word;
}

@mixin title() {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;

  @media (min-width: $breakpoint-tablet) {
    font-size: 30px;
    line-height: 36px;
  }

  @media (min-width: $breakpoint-desktop) {
    font-size: 48px;
    line-height: 54px;
  }
}

@mixin container() {
  padding: 0 10px;

  @media (min-width: $breakpoint-tablet) {
    width: 720px;
    margin: 0 auto;
    padding: 0;
  }

  @media (min-width: $breakpoint-desktop) {
    width: 1240px;
  }

  @media (min-width: $breakpoint-big-desktop) {
    width: 1670px;
  }
}
