.news-item {
  margin-bottom: 60px;
  padding-top: 115px;
}

.news-item__container {
  @include container;
  position: relative;
}

.news-item__title {
  @include title;
  margin: 0;
  margin-bottom: 25px;
}

.news-item__date {
  display: block;
  width: 100vw;
  margin-left: -10px;
  margin-bottom: 20px;
  padding: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  background-color: $yellow;

  & span {
    font-size: 13px;
  }
}

.news-item__block {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 15px;
  margin-bottom: 20px;
}

.news-item__text {
  margin: 0;
  font-size: 13px;
  line-height: 19px;

  & a {
    font-style: italic;
    color: $base-color;
    text-decoration-color: $yellow;
  }

  &.margin {
    margin-bottom: 10px;
  }
}

.news-item__list {
  margin: 10px 0;
  padding: 0;
  list-style: none;
  font-size: 13px;
  line-height: 19px;

  & li {
    position: relative;
    margin-bottom: 3px;
    padding-left: 25px;

    &::before {
      content: "";
      position: absolute;
      top: 8px;
      left: 5px;
      display: block;
      width: 8px;
      height: 8px;
      background-color: $yellow;
      transform: rotate(45deg);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.news-item__image {
  display: block;
  width: 100%;
  height: auto;

  & img {
    display: block;
    width: 100%;
    height: auto;
  }

  &.no-mobile {
    display: none;
  }
}

.news-item__caption {
  margin: 40px 0 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
}

.news-item__col {
  margin-bottom: 10px;
}

.news-item__more {
  margin-bottom: 50px;
}

@media (min-width: $breakpoint-tablet) {
  .news-item {
    margin-bottom: 100px;
    padding-top: 150px;
  }

  .news-item__title {
    margin-bottom: 35px;
  }

  .news-item__date {
    position: absolute;
    right: 0;
    top: 50px;
    width: auto;
    margin: 0;
    padding: 15px;
    font-size: 20px;
    line-height: 20px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      bottom: 0;
      width: 1000px;
      background-color: $yellow;
    }

    & span {
      font-size: 16px;
    }
  }

  .news-item__block {
    display: flex;
    margin-bottom: 40px;

    &:nth-of-type(odd) {
      flex-direction: row-reverse;

      & .news-item__image {
        margin: 0;
        margin-right: 30px;
      }
    }

    & .news-item__image {
      width: 315px;
      height: 295px;
      margin: 0;
      margin-left: 30px;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .news-item__wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 345px);
  }

  .news-item__text {
    font-size: 14px;
    line-height: 20px;
  }

  .news-item__list {
    margin: 15px 0;
    font-size: 14px;
    line-height: 20px;
  }

  .news-item__caption {
    margin: 50px 0 25px;
    font-size: 20px;
    line-height: 24px;
  }

  .news-item__text-wrap {
    margin-bottom: 30px;
  }

  .news-item__image {
    &.inline {
      display: inline-block;
      width: calc(50% - 10px);
      height: 300px;

      &:first-of-type {
        margin-right: 15px;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .news-item {
    & .form {
      overflow: visible;
    }
  }

  .news-item__title {
    margin-bottom: 60px;
  }

  .news-item__date {
    top: 70px;
    padding: 20px;
    font-size: 30px;
    line-height: 27px;

    & span {
      font-size: 20px;
    }
  }

  .news-item__block {
    justify-content: space-between;

    & .news-item__image {
      width: calc(50% - 10px);
      height: 390px;
      margin: 0;
    }

    &:nth-of-type(odd) {
      & .news-item__image {
        margin: 0;
      }

      & .news-item__wrap {
        padding: 0;
        padding-left: 80px;
      }
    }
  }

  .news-item__wrap {
    width: calc(50% - 10px);
    padding-right: 40px;
  }

  .news-item__text {
    font-size: 16px;
    line-height: 24px;
  }

  .news-item__list {
    margin: 20px 0;
    font-size: 16px;
    line-height: 24px;
  }

  .news-item__caption {
    margin: 80px 0 30px;
    font-size: 24px;
    line-height: 28px;
  }

  .news-item__text-wrap {
    column-count: 2;
  }

  .news-item__image {
    &.inline {
      height: 370px;
    }
  }

  .news-item__more {
    position: relative;
    z-index: 3;
    margin: 0;
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .news-item__title {
    margin-bottom: 80px;
  }

  .news-item__block {
    margin-bottom: 50px;

    & .news-item__image {
      width: calc(50% - 50px);
      height: 400px;
    }

    &:nth-of-type(odd) {
      & .news-item__wrap {
        padding: 0;
      }
    }
  }

  .news-item__wrap {
    width: calc(50% - 50px);
    padding: 0;
  }

  .news-item__text {
    font-size: 18px;
    line-height: 26px;
  }

  .news-item__list {
    margin: 30px 0;
    font-size: 18px;
    line-height: 26px;

    & li {
      padding-left: 30px;

      &::before {
        top: 10px;
        width: 10px;
        height: 10px;
      }
    }
  }

  .news-item__caption {
    margin: 90px 0 40px;
    font-size: 30px;
    line-height: 36px;
  }

  .news-item__text-wrap {
    & .news-item__text {
      margin-right: 40px;
    }
  }

  .news-item__image {
    &.inline {
      width: calc(50% - 15px);
      height: 400px;

      &:first-of-type {
        margin-right: 25px;
      }
    }
  }
}
