.production {
  margin-bottom: 60px;
  padding-top: 115px;
}

.production__container {
  @include container;
}

.production__title {
  @include title;
  margin: 0;
  margin-bottom: 20px;
}

.production__list-link {
  margin: 0;
  margin-bottom: 30px;
  padding: 0;
  list-style: none;

  & li {
    margin-bottom: 15px;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.production__link {
  position: relative;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  text-decoration: none;

  &.active,
  &:hover {
    &::before {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: $yellow;
    }
  }
}

.production__video {
  position: relative;
  display: block;
  height: 235px;
  margin-bottom: 30px;

  & picture {
    display: block;
    width: 100%;
    height: 100%;

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.25);
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 0;
      height: 0;
      border: 20px solid $yellow;
      border-right: none;
      border-left-width: 25px;
      border-top-color: transparent;
      border-bottom-color: transparent;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

.production__desc {
  margin-bottom: 30px;

  & > p {
    margin: 0;
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 19px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.production__feature {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0;
  margin-bottom: 40px;
  padding: 0;
  list-style: none;

  & li {
    margin: 0 25px 20px;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #5c5b5b;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }

    & b {
      display: block;
      font-weight: 700;
      font-size: 30px;
      line-height: 30px;
      color: #000000;
    }

    & span {
      font-size: 18px;
    }
  }
}

.production__subtitle {
  margin: 0;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.production__image {
  margin: 0;
  margin-bottom: 25px;

  & picture,
  & img {
    display: block;
    width: 100%;
    height: auto;
  }

  & figcaption {
    margin-top: 20px;
    font-size: 13px;
    line-height: 19px;
    font-style: italic;
    text-align: center;
  }
}

.production__gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  margin: 0;
  margin-bottom: 60px;
  padding: 0;
  list-style: none;
  counter-reset: count;
}

.production__item {
  position: relative;
  height: 305px;
  padding-top: 70px;

  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::before {
    counter-increment: count;
    content: counter(count);
    position: absolute;
    top: 13px;
    left: 50%;
    z-index: 1;
    font-weight: 900;
    font-size: 24px;
    line-height: 24px;
    color: #ffffff;
    transform: translateX(-20%);
  }

  &::after {
    content: "";
    position: absolute;
    top: 20px;
    left: 50%;
    display: block;
    width: 35px;
    height: 35px;
    background-color: $yellow;
    transform: rotate(45deg) translateX(-50%);
  }
}

@media (min-width: $breakpoint-tablet) {
  .production {
    margin-bottom: 100px;
    padding-top: 150px;
  }

  .production__list-link {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    & li {
      margin: 0 10px 20px;
    }
  }

  .production__link {
    font-size: 16px;
    line-height: 20px;
  }

  .production__video {
    height: 400px;
    margin-bottom: 40px;
  }

  .production__desc {
    margin-bottom: 60px;

    & > p {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .production__feature {
    margin-bottom: 60px;

    & li {
      margin: 0 20px 20px;
      font-size: 14px;
      line-height: 20px;

      &:nth-last-child(2) {
        margin-bottom: 0;
      }

      & b {
        font-size: 36px;
        line-height: 36px;
      }

      & span {
        font-size: 24px;
      }
    }
  }

  .production__subtitle {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 28px;
  }

  .production__image {
    & figcaption {
      margin-top: 30px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .production__gallery {
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-column-gap: 10px;
    grid-row-gap: 30px;
  }

  .production__item {
    height: 300px;
    padding-top: 60px;
  }
}

@media (min-width: $breakpoint-desktop) {
  .production {
    & .form {
      overflow: visible;
    }
  }

  .production__title {
    margin-bottom: 60px;
  }

  .production__list-link {
    margin-bottom: 40px;

    & li {
      margin: 0;
    }
  }

  .production__link {
    font-size: 18px;
    line-height: 26px;
  }

  .production__video {
    margin-bottom: 60px;

    & picture::after {
      border-width: 40px;
      border-left-width: 45px;
    }
  }

  .production__desc {
    column-count: 2;
    column-gap: 80px;

    & > p {
      font-size: 16px;
      line-height: 24px;

      & b {
        font-size: 20px;
      }
    }
  }

  .production__feature {
    margin-bottom: 80px;

    & li {
      margin: 0;
      font-size: 16px;
      line-height: 24px;

      & b {
        font-size: 48px;
        line-height: 48px;
      }

      & span {
        font-size: 20px;
      }
    }
  }

  .production__subtitle {
    font-size: 40px;
    line-height: 46px;
  }

  .production__image {
    margin-bottom: 40px;

    & figcaption {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .production__gallery {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 40px;
  }

  .production__item {
    height: 380px;
    padding-top: 80px;

    &::after {
      top: 25px;
      width: 45px;
      height: 45px;
    }

    &::before {
      top: 20px;
      transform: translateX(-13%);
    }
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .production__title {
    margin-bottom: 80px;
  }

  .production__list-link {
    margin-bottom: 60px;
  }

  .production__link {
    font-size: 20px;
    line-height: 28px;
  }

  .production__video {
    height: 500px;
    margin-bottom: 80px;
  }

  .production__desc {
    column-gap: 100px;

    & > p {
      font-size: 18px;
      line-height: 26px;

      & b {
        font-size: 22px;
      }
    }
  }

  .production__feature {
    margin-bottom: 100px;

    & li {
      font-size: 18px;
      line-height: 26px;

      & b {
        font-size: 50px;
        line-height: 50px;
      }

      & span {
        font-size: 22px;
      }
    }
  }

  .production__image {
    & figcaption {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .production__gallery {
    grid-template-columns: repeat(auto-fit, minmax(535px, 1fr));
    grid-column-gap: 25px;
    grid-row-gap: 45px;
  }

  .production__item {
    height: 500px;
  }
}
