.nav {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  width: 0;
  max-height: 2px;
  background-color: #ffffff;
  overflow: hidden;
  transition: width 0.15s ease 0.15s, right 0.15s ease 0.15s, max-height 0.15s ease 0s, top 0.15s ease 0s;

  &.show {
    top: 0;
    right: 0;
    width: 100%;
    max-height: 2000px;
    transition: width 0.15s ease 0s, right 0.15s ease 0s, max-height 1s ease 0.2s, top 0.4s ease 0.15s;
  }
}

.nav__list {
  height: 100vh;
  margin: 0;
  padding: 50px 10px;
  list-style: none;
  text-align: center;
  overflow-y: auto;
}

.nav__item {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.nav__link {
  position: relative;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  text-decoration: none;

  &--active {
    border-bottom: 1px solid $yellow;
  }

  &--submenu {
    &::before {
      content: "+";
      position: absolute;
      top: -1px;
      left: -12px;
    }
  }

  &.open {
    &::before {
      content: "-";
      position: absolute;
      top: -1px;
      left: -12px;
    }

    & + .nav__submenu {
      max-height: 1000px;
      transition: all 0.8s ease;
    }
  }
}

.nav__submenu {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.nav__submenu-list {
  margin: 0;
  padding: 0;
  list-style: none;

  & li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 5px;
    }
  }
}

.nav__submenu-wrap {
  padding-top: 10px;
}

.nav__submenu-link {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  text-decoration: none;

  &.active {
    border-bottom: 1px solid $yellow;
  }
}

@media (min-width: $breakpoint-tablet) {
  .nav {
    position: static;
    width: 100%;
    max-height: none;
    background-color: transparent;
    overflow: visible;
  }

  .nav__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto;
    padding: 0;
    text-align: left;
    overflow: visible;
  }

  .nav__item {
    position: relative;
    margin-bottom: 5px;
  }

  .nav__link {
    position: relative;
    display: inline-block;
    padding: 5px;
    font-weight: 500;
    color: #ffffff;

    &--active,
    &:hover {
      border: none;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: auto;
        bottom: 3px;
        height: 1px;
        background-color: $yellow;
      }
    }

    &--submenu {
      padding-left: 15px;
      transition: all 0.1s ease 0.35s;

      &::before {
        top: 4px;
        left: 3px;
      }

      &:hover {
        &::before {
          content: "+";
          position: absolute;
          top: 4px;
          left: 3px;
          right: auto;
          bottom: auto;
          height: auto;
          background-color: transparent;
        }
      }

      &.open {
        z-index: 31;
        color: #000000;
        background-color: #ffffff;
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
        transition: all 0.1s ease 0s;

        &::before {
          content: "-";
          position: absolute;
          top: 4px;
          left: 3px;
        }
      }
    }
  }

  .nav__submenu {
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 30;
    background-color: #ffffff;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
    transform: translateX(-50%);

    &--big {
      left: -112px;
      width: 720px;
      transform: none;
    }
  }

  .nav__submenu-wrap {
    display: flex;
    justify-content: space-between;
    min-width: 260px;
    padding: 20px;
  }

  .nav__submenu-list {
    & li {
      margin-bottom: 5px;
    }
  }

  .nav__submenu-link {
    font-size: 13px;
    line-height: 24px;
    border-bottom: 1px solid transparent;

    &:hover {
      border-color: $yellow;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .nav__item {
    &--submenu {
      &:hover {
        & .nav__link--submenu {
          z-index: 32;
          color: #000000;
          background-color: #ffffff;
          box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
          transition: all 0.1s ease 0s;

          &::before {
            content: "-";
            position: absolute;
            top: 4px;
            left: 6px;
          }
        }

        & .nav__submenu {
          max-height: 1000px;
          transition: all 0.8s ease;
        }
      }
    }
  }

  .nav__link {
    padding: 5px 8px;
    font-size: 16px;
    line-height: 16px;

    &--submenu {
      padding-left: 20px;

      &::before {
        left: 6px;
      }
    }
  }

  .nav__submenu {
    left: 0;
    transform: none;

    &--big {
      width: 975px;
    }

    &:hover {
      max-height: 1000px;
      transition: all 0.8s ease;
    }
  }

  .nav__submenu-wrap {
    padding: 25px;
  }

  .nav__submenu-link {
    font-size: 16px;
    line-height: 26px;

    &--all {
      display: none;
    }
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .nav {
    padding: 0 40px;
  }

  .nav__item--submenu:hover {
    & .nav__link--submenu::before {
      top: 7px;
      left: 10px;
    }
  }

  .nav__link {
    padding: 8px 0;
    font-size: 18px;
    line-height: 18px;

    &--submenu {
      padding: 8px 10px;
      padding-left: 25px;

      &::before {
        top: 7px;
      }
    }
  }

  .nav__submenu {
    &--big {
      width: 1080px;
    }
  }

  .nav__submenu-link {
    font-size: 18px;
    line-height: 28px;
  }
}
