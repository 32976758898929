.special-offers {
  margin-bottom: 60px;
  padding-top: 100px;
}

.special-offers__content {
  margin-bottom: 60px;
}

.special-offers__item {
  position: relative;
  min-height: 400px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(135deg, #ffffff 15%, $yellow);
    opacity: 0;
    transition: all 0.3s ease;
  }

  &:hover {
    &::before {
      opacity: 0.8;
      transition: all 0.3s ease;
    }

    & .special-offers__block {
      color: #000000;
      transition: color 0.2s ease;
    }

    & .special-offers__show-btn {
      border-color: #000000;
      transition: border-color 0.2s ease;

      &::before {
        border-color: #000000;
        transition: border-color 0.2s ease;
      }
    }
  }
}

.special-offers__image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;

  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to right bottom, rgba(0, 0, 0, 0.65), transparent);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.special-offers__block {
  position: relative;
  z-index: 3;
  padding: 60px 20px;
  color: #ffffff;
  transition: color 0.2s ease;
}

.special-offers__caption {
  margin: 0;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.special-offers__wrapper {
  max-height: 0;
  margin: 0;
  overflow: hidden;
  transition: max-height 0.2s ease, margin-bottom 0.2s ease 0.2s;

  &.open {
    max-height: 5000px;
    margin-bottom: 20px;
    transition: max-height 0.6s ease, margin-bottom 0.4s ease;
  }

  & p {
    font-size: 13px;
    line-height: 19px;

    & b {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.special-offers__show-btn {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 0;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  transition: border-color 0.2s ease;

  &::before {
    content: "";
    position: absolute;
    top: 9px;
    left: 10px;
    display: block;
    width: 8px;
    height: 8px;
    border-left: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    transform: rotate(-45deg);
    transition: border-color 0.2s ease;
  }

  &.open {
    transform: rotate(180deg);
  }
}

@media (min-width: $breakpoint-tablet) {
  .special-offers {
    margin-bottom: 100px;
    padding-top: 130px;
  }

  .special-offers__container {
    @include container;
  }

  .special-offers__content {
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 80px;
  }

  .special-offers__block {
    padding: 90px 80px;
  }

  .special-offers__caption {
    font-size: 24px;
    line-height: 28px;
  }

  .special-offers__wrapper {
    & p {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .special-offers {
    padding-top: 110px;

    & .form {
      overflow: visible;
    }
  }

  .special-offers__content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .special-offers__item {
    width: calc(100% / 3);
    min-height: 920px;
  }

  .special-offers__block {
    padding: 130px 40px 40px;
  }

  .special-offers__caption {
    margin-bottom: 40px;
    font-size: 30px;
    line-height: 34px;
  }

  .special-offers__wrapper {
    & p {
      font-size: 16px;
      line-height: 24px;

      & b {
        font-size: 20px;
      }
    }
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .special-offers {
    padding-top: 115px;
  }

  .special-offers__block {
    padding: 130px 60px 60px;
  }

  .special-offers__caption {
    font-size: 32px;
    line-height: 36px;
  }

  .special-offers__wrapper {
    & p {
      font-size: 18px;
      line-height: 26px;

      & b {
        font-size: 22px;
      }
    }
  }
}
