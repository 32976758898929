.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(43,43,43,0.7);
  box-shadow: 0 5px 13px rgba(0,0,0,0.2);
}

.header__container {
  @include container;
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-bottom: 15px;
}

.header__logo-wrapper {
  margin-right: 10px;
  padding-top: 10px;
}

.header__logo {
  display: block;
  width: 64px;
  height: 45px;
  background: url("../images/svg/logo-header.svg") no-repeat center/contain;
}

.header__logo-text {
  display: none;
}

.header__wrapper {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.header__btn-calc {
  order: -4;
  margin-bottom: 10px;
}

.header__city {
  position: relative;
  z-index: 20;
  justify-content: flex-end;
  width: 100%;
  margin-left: auto;
  font-size: 12px;
  line-height: 14px;

  & .select__title {
    color: #ffffff;
  }

  & .select__selected {
    color: #ffffff;
    border-color: #ffffff;
    transition: color 0s ease 0.3s, border-color 0s ease 0.3s;

    &::after {
      border-color: #ffffff;
      transition: border-color 0s ease 0.3s;
    }

    &.show {
      color: #000000;
      border-color: #000000;

      &::after {
        border-color: #000000;
      }
    }
  }
}

.header__contacts {
  order: -1;
  position: relative;
  z-index: 20;
  width: 100%;
  margin-left: auto;
  margin-bottom: 15px;
  text-align: right;
}

.header__contacts-caption {
  position: relative;
  z-index: 11;
  display: inline-block;
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  transition: color 0s ease 0.25s;

  &::before {
    content: "";
    position: absolute;
    top: 4px;
    left: -13px;
    display: block;
    width: 6px;
    height: 6px;
    border-left: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    transform: rotate(-45deg);
    transition: border-color 0s ease 0.25s;
  }

  &::after {
    content: "";
    position: absolute;
    top: -10px;
    right: -10px;
    left: -25px;
    bottom: -10px;
    z-index: -1;
    background-color: transparent;
    transition: background-color 0.2s ease 0.25s, box-shadow 0.2s ease 0.25s;
  }

  &.open {
    color: #000000;
    transition: color 0s ease 0s;

    &::after {
      background-color: #ffffff;
      box-shadow: -2px -5px 10px rgba(0,0,0,0.2);
      transition: background-color 0s ease 0s, box-shadow 0s ease 0s;
    }

    &::before {
      top: 7px;
      border-color: #000000;
      transform: rotate(135deg);
      transition: border-color 0s ease 0s;
    }

    & + .header__contacts-content {
      max-height: 1000px;
      transition: max-height 0.6s ease;
    }
  }
}

.header__contacts-content {
  position: absolute;
  top: 25px;
  right: -10px;
  z-index: 10;
  width: 300px;
  max-height: 0;
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  overflow: hidden;
  transition: max-height 0.2s ease;
}

.header__contacts-wrap {
  padding: 50px 20px 20px;
  text-align: left;
}

.header__phone {
  position: relative;
  display: block;
  margin-bottom: 15px;
  padding-left: 30px;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  text-decoration: none;

  &::before {
    content: "";
    position: absolute;
    top: -4px;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    background: url("../images/png/phone.png") no-repeat center/contain;
  }
}

.header__mail {
  position: relative;
  display: block;
  margin-bottom: 15px;
  padding-left: 30px;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  text-decoration: none;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 22px;
    height: 14px;
    background: url("../images/png/mail.png") no-repeat center/contain;
  }
}

.header__address {
  position: relative;
  padding-left: 30px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 1px;
    display: block;
    width: 19px;
    height: 25px;
    background: url("../images/png/pin.png") no-repeat center/contain;
  }

  & p {
    margin: 0;
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 18px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.header__nav {
  order: -3;
  margin-left: auto;
  padding-top: 7px;
}

.header__toggle-menu {
  position: relative;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 25px;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    width: 30px;
    height: 2px;
    background-color: #ffffff;
  }

  &::before {
    top: 3px;
    transition: top 0.2s ease 0.2s, transform 0.2s ease 0s, background-color 0s ease 0.3s;
  }

  &::after {
    bottom: 3px;
    transition: bottom 0.2s ease 0.2s, transform 0.2s ease 0s, background-color 0s ease 0.3s;
  }

  & span {
    display: block;
    width: 30px;
    height: 2px;
    background-color: #ffffff;
    transition: opacity 0s ease 0.2s, background-color 0.3s ease;
  }

  &.toggle {
    &::before {
      top: 50%;
      margin-top: -1px;
      background-color: $yellow;
      transform: rotate(45deg);
      transition: top 0.2s ease 0s, transform 0.2s ease 0.2s;
    }

    &::after {
      bottom: 50%;
      margin-bottom: -1px;
      background-color: $yellow;
      transform: rotate(-45deg);
      transition: bottom 0.2s ease 0s, transform 0.2s ease 0.2s;
    }

    & span {
      opacity: 0;
      transition: opacity 0s ease 0.2s;
    }
  }
}

.header__search.search {
  order: -2;
  position: absolute;
  top: 42px;
  left: 85px;
}

.header__lang {
  position: absolute;
  left: 19px;
  top: 65px;

  & a {
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none;

    &.active {
      color: $yellow;
    }

    &:first-of-type {
      margin-right: 5px;

      &::after {
        content: "|";
        margin-left: 5px;
      }
    }
  }
}

.header-white,
.header-inner {
  background-color: #ffffff;
  transition: background-color 0.2s ease;

  & .header__logo {
    background-image: url("../images/svg/logo.svg");
  }

  & .header__logo-text {
    color: #000000;
  }

  & .header__toggle-menu {
    &::before,
    &::after,
    & span {
      background-color: #000000;
      transition: background-color 0.3s ease;
    }

    &.toggle {
      &::before,
      &::after {
        background-color: $yellow;
      }
    }
  }

  & .header__lang a {
    color: #000000;

    &.active {
      color: $yellow;
    }
  }

  & .header__search.search {
    & .search__btn {
      background-image: url("../images/svg/search.svg");

      &:hover {
        background-image: url("../images/svg/search-hover.svg");
      }
    }

    &.open .search__btn {
      background-image: url("../images/svg/search-hover.svg");
    }
  }

  & .header__contacts-caption {
    color: #000000;
    transition: none;

    &::before {
      border-color: #000000;
      transition: none;
    }
  }

  & .header__city {
    & .select__title {
      color: #000000;
    }

    & .select__selected {
      color: #000000;
      border-color: #000000;
      transition: none;

      &::after {
        border-color: #000000;
        transition: none;
      }
    }
  }

  & .nav__link {
    color: #000000;
  }

  & .header__nav {
    border-color: rgba(185, 185, 186, 0.3);
  }
}

@media (min-width: $breakpoint-tablet) {
  .header {
    padding: 10px 0;
  }

  .header__logo-wrapper {
    margin-right: 15px;
    padding-top: 0;
  }

  .header__logo {
    width: 97px;
    height: 68px;
  }

  .header__wrapper {
    position: relative;
    align-items: baseline;
  }

  .header__city {
    z-index: 45;
    order: -4;
    width: auto;
    margin-left: 0;
    margin-right: 40px;

    & .select__dropdown {
      z-index: 33;
    }
  }

  .header__search.search {
    order: 0;
    position: absolute;
    top: 55px;
    left: auto;
    right: 120px;
  }

  .header__btn-calc {
    order: -2;
    margin: 0;
  }

  .header__contacts {
    order: -3;
    width: auto;
    margin: 0;
    margin-right: auto;
  }

  .header__contacts-caption {
    font-size: 16px;
    line-height: 18px;
  }

  .header__contacts-wrap {
    padding-top: 25px;
  }

  .header__phone,
  .header__mail,
  .header__address p {
    font-size: 14px;
    line-height: 20px;
  }

  .header__phone::before {
    top: -3px;
  }

  .header__mail::before,
  .header__address::before {
    top: 3px;
  }

  .header__lang {
    position: absolute;
    top: 60px;
    right: 30px;
    left: auto;

    & a {
      font-size: 14px;
      line-height: 16px;
    }
  }

  .header__nav {
    order: -1;
    width: 410px;
    margin: 0;
    padding-top: 10px;
    border-top: 1px solid rgba(255,255,255, 0.3);
  }

  .header__toggle-menu {
    display: none;
  }
}

@media (min-width: $breakpoint-desktop) {
  .header__logo-wrapper {
    display: flex;
    margin-right: 40px;
  }

  .header__logo {
    width: 131px;
    height: 93px;
  }

  .header__logo-text {
    display: block;
    margin: 0;
    margin-left: 5px;
    margin-top: auto;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }

  .header__wrapper {
    align-items: center;
  }

  .header__city {
    order: 0;
  }

  .header__search.search {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    z-index: 35;
    margin-right: 40px;
  }

  .header__btn-calc {
    order: 0;
    margin-bottom: 5px;
    margin-right: 50px;

    &:hover {
      filter: brightness(120%);
    }
  }

  .header__contacts {
    order: 0;
    margin: 0;
    margin-right: auto;
  }

  .header__contacts-caption {
    font-size: 18px;
    line-height: 20px;

    &::before {
      top: 6px;
    }

    &.open::before {
      top: 9px;
    }
  }

  .header__contacts-content {
    width: 350px;
  }

  .header__contacts-wrap {
    padding: 30px;
  }

  .header__phone,
  .header__mail,
  .header__address {
    padding-left: 40px;
    font-size: 16px;
    line-height: 22px;
  }

  .header__phone::before {
    top: -4px;
    width: 25px;
    height: 25px;
  }

  .header__mail::before {
    top: 1px;
    width: 25px;
    height: 18px;
  }

  .header__address {
    & p {
      font-size: 16px;
      line-height: 22px;
    }

    &::before {
      width: 22px;
      height: 28px;
    }
  }

  .header__lang {
    position: static;

    & a {
      font-size: 16px;
      line-height: 18px;
    }
  }

  .header__nav {
    order: 0;
    width: 100%;
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .header__logo-wrapper {
    margin-right: 130px;
  }

  .header__logo {
    width: 143px;
    height: 101px;
  }

  .header__city {
    margin-right: auto;
  }

  .header__contacts {
    margin-right: 40px;
  }
}
