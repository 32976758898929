.select {
  display: inline-flex;
  align-items: center;
}

.select__title {
  margin: 0;
  margin-right: 10px;
  font-size: 12px;
}

.select__dropdown {
  position: relative;
  z-index: 10;
}

.select__selected {
  position: relative;
  z-index: 2;
  width: 120px;
  padding: 0 5px;
  border-bottom: 1px solid $base-color;

  &::after {
    content: '';
    position: absolute;
    top: 4px;
    right: 5px;
    display: block;
    width: 6px;
    height: 6px;
    border-left: 1px solid $base-color;
    border-bottom: 1px solid $base-color;
    transform: rotate(-45deg);
  }

  & span {
    position: relative;
    display: inline-block;
    width: 105px;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.show {
    &::after {
      top: 6px;
      transform: rotate(135deg);
    }
  }
}

.select__wrapper {
  position: absolute;
  top: -10px;
  right: -15px;
  left: -15px;
  max-height: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 16px rgba(19, 22, 26, 0.2);
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.3s ease, opacity 0.2s ease;
  transition-delay: 0s, 0.3s;

  &.show {
    max-height: 1000px;
    opacity: 1;
    transition: max-height 0.3s ease, opacity 0.2s ease;
    transition-delay: 0.2s, 0s;
  }
}

.select__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  padding: 45px 20px;
  padding-bottom: 10px;
  list-style: none;

  & li {
    margin-bottom: 3px;
    font-size: 13px;
    line-height: 19px;
    border-bottom: 1px solid transparent;

    &.active {
      border-color: $yellow;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .select__title {
    font-size: 14px;
    line-height: 16px;
  }

  .select__selected {
    width: 130px;

    & span {
      width: 110px;
      font-size: 15px;
      line-height: 17px;
    }
  }

  .select__list li {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (min-width: $breakpoint-desktop) {
  .select__title {
    font-size: 16px;
    line-height: 18px;
  }

  .select__selected {
    width: 170px;

    & span {
      width: 150px;
      font-size: 17px;
      line-height: 19px;
    }
  }

  .select__list li {
    font-size: 16px;
    line-height: 22px;
  }
}
