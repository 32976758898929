.button {
  display: inline-block;
  padding: 10px 15px;
  font-family: inherit;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
  background-color: $yellow;
  text-decoration: none;
  text-align: center;
  border: none;
  transition: all 0.2s;

  &:hover {
    filter: brightness(120%);
    box-shadow: 0 5px 15px rgba(19, 22, 26, 0.2);
    transition: all 0.2s;
  }

  &--transparent {
    color: #ffffff;
    background-color: transparent;
    border: 2px solid $yellow;
    transition: all 0.2s;

    &:hover {
      color: #000000;
      background-color: $yellow;
      transition: all 0.2s;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .button {
    padding: 12px 20px;
    font-size: 14px;
    line-height: 14px;
  }
}

@media (min-width: $breakpoint-desktop) {
  .button {
    padding: 15px 25px;
    font-size: 16px;
    line-height: 16px;
  }
}
