.tech-tables {
  margin-bottom: 60px;
  padding-top: 115px;
}

.tech-tables__container {
  @include container;
}

.tech-tables__title {
  @include title;
  margin: 0;
  margin-bottom: 25px;
}

.tech-tables__attention {
  margin: 0;
  margin-bottom: 20px;
  font-size: 13px;
  line-height: 19px;
  color: gray;
  text-align: center;
}

.tech-tables__list {
  margin: 0;
  margin-bottom: 80px;
  padding: 0;
  list-style: none;
}

.tech-tables__item {
  position: relative;
}

.tech-tables__subtitle {
  position: relative;
  display: inline-block;
  margin: 0;
  margin-bottom: 15px;
  padding-left: 25px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  &::before {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    width: 10px;
    height: 10px;
    background-color: $yellow;
    transform: rotate(45deg);
  }

  &.active {
    text-decoration: underline;
    text-decoration-color: $yellow;
  }
}

.tech-tables__content {
  max-height: 0;
  overflow: hidden;

  &.active {
    max-height: 10000px;
  }
}

.tech-tables__wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 25px;
  margin-bottom: 30px;

  &--small {
    grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));

    & .tech-tables__block {
      flex-direction: column;

      & img {
        margin: 0;
        margin-bottom: 10px;
      }

      & p {
        text-align: center;
      }
    }
  }
}

.tech-tables__block {
  display: flex;
  align-items: center;
  margin: 0;

  & img {
    display: block;
    width: 75px;
    height: 75px;
    margin-right: 15px;
    object-fit: contain;
  }

  & > div {
    width: calc(100% - 95px);
  }

  & p {
    margin: 0;
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 17px;
  }
}

.tech-tables__image {
  position: relative;
  display: block;
  margin-bottom: 20px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    background: url("../images/svg/loupe.svg") no-repeat center/contain;
    transform: translateX(-50%) translateY(-50%);
  }

  & picture,
  & img {
    display: block;
    width: 100%;
    height: auto;
  }

  &--small {
    &::after {
      width: 20px;
      height: 20px;
    }
  }
}

.tech-tables__info {
  position: relative;
  margin-bottom: 20px;
  padding: 15px;
  padding-left: 55px;
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 10px;
    display: block;
    width: 13px;
    height: 53px;
    background: url("../images/svg/info.svg") no-repeat center/contain;
    transform: translateY(-50%);
  }

  & p {
    margin: 0;
    font-size: 13px;
    line-height: 19px;
  }
}

.tech-tables__desc {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;

  & div {
    flex-basis: 300px;
    flex-grow: 1;
    margin: 0 10px;
    margin-bottom: 20px;
  }

  & figure {
    margin: 0;
  }

  & figcaption {
    margin: 0;
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 19px;
  }

  & img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.tech-tables__caption {
  margin: 0;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 13px;
  line-height: 19px;
  text-transform: uppercase;
}

.tech-tables__text {
  margin: 0;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 19px;
}

.tech-tables__figure {
  margin: 0;
  margin-bottom: 20px;

  & figcaption {
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 19px;
    text-align: center;

    &.text-align-left {
      text-align: left;
    }
  }
}

.tech-tables__sublist {
  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  list-style: none;

  & li {
    position: relative;
    margin-bottom: 10px;
    padding-left: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: 6px;
      left: 5px;
      display: block;
      width: 7px;
      height: 7px;
      background-color: $yellow;
      transform: rotate(45deg);
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .tech-tables {
    margin-bottom: 100px;
    padding-top: 150px;
  }

  .tech-tables__title {
    margin-bottom: 30px;
  }

  .tech-tables__attention {
    margin-bottom: 25px;
  }

  .tech-tables__subtitle {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 1.3;
    cursor: pointer;

    &::before {
      width: 12px;
      height: 12px;
    }

    &:hover,
    &.active {
      text-decoration: none;

      &::after {
        content: "";
        position: absolute;
        left: 25px;
        right: 0;
        bottom: -2px;
        height: 1px;
        background-color: $yellow;
      }
    }
  }

  .tech-tables__wrap {
    grid-template-columns: 1fr 1fr;

    &--small {
      grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
    }
  }

  .tech-tables__block {
    & img {
      width: 80px;
      height: 80px;
      margin-right: 20px;
    }

    & > div {
      width: calc(100% - 105px);
    }

    & p {
      font-size: 14px;
      line-height: 1.3;
    }
  }

  .tech-tables__image {
    margin-bottom: 30px;

    &::after {
      width: 60px;
      height: 60px;
    }

    &--small {
      &::after {
        width: 30px;
        height: 30px;
      }
    }
  }

  .tech-tables__info {
    margin-bottom: 30px;
    padding: 20px;
    padding-left: 55px;

    &::before {
      left: 15px;
    }

    & p {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .tech-tables__desc {
    margin-bottom: 30px;

    & > div {
      flex-basis: auto;
      flex-grow: 0;
      width: calc(50% - 45px);
      margin: 0;
      margin-bottom: 25px;
    }

    & figure {
      & figcaption {
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .tech-tables__caption {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 1.3;
  }

  .tech-tables__text {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 20px;

    &--margin-bottom {
      margin-bottom: 30px;
    }
  }

  .tech-tables__figure {
    margin-bottom: 30px;

    & figcaption {
      margin-bottom: 20px;
      padding-left: 25px;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
    }

    &--size-m {
      display: flex;
      flex-direction: column-reverse;
      width: calc(50% - 10px);
      margin-left: auto;
      margin-top: -150px;

      & figcaption {
        padding-left: 0;
      }

      & .tech-tables__image {
        margin-bottom: 10px;
      }
    }

    &--size-s {
      display: inline-block;
      width: 233px;

      & figcaption {
        padding-left: 0;
      }

      & .tech-tables__image {
        margin-bottom: 0;
      }
    }

    &--size-2m {
      display: inline-block;
      width: 480px;
      margin-left: 2px;

      & .tech-tables__image {
        margin-bottom: 0;
      }
    }
  }

  .tech-tables__flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    &--size-s {
      & > .tech-tables__image {
        width: calc(100% / 3 - 10px);
        margin-bottom: 0;
      }
    }

    &--size-m {
      & > .tech-tables__image {
        width: calc(50% - 10px);
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .tech-tables__sublist {
    & li {
      padding-left: 25px;
      font-size: 14px;
      line-height: 20px;

      &::before {
        width: 8px;
        height: 8px;
      }
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .tech-tables {
    & .form {
      overflow: visible;
    }
  }

  .tech-tables__title {
    margin-bottom: 60px;
  }

  .tech-tables__attention {
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }

  .tech-tables__list {
    position: relative;
    z-index: 2;
    margin-bottom: 40px;
  }

  .tech-tables__subtitle {
    padding-left: 35px;
    font-size: 24px;
    line-height: 1.4;

    &::before {
      left: 6px;
      top: 10px;
      width: 14px;
      height: 14px;
    }

    &:hover,
    &.active {
      &::after {
        left: 35px;
      }
    }
  }

  .tech-tables__wrap {
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 40px;

    &--small {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-gap: 15px;

      & .tech-tables__block {
        flex-direction: row;
        align-items: flex-end;

        & img {
          width: 112px;
          height: 112px;
          margin: 0;
        }

        & p {
          margin: 0;
          margin-left: -10px;
          text-align: left;
        }
      }
    }

    &--medium {
      grid-template-columns: repeat(3, 260px) 1fr;
      grid-gap: 10px;

      & .tech-tables__block {
        & img {
          width: 110px;
          height: 110px;
        }
      }
    }
  }

  .tech-tables__block {
    & img {
      width: 130px;
      height: 130px;
    }

    & > div {
      width: calc(100% - 150px);
    }

    & p {
      margin-bottom: 10px;
      font-size: 16px;
    }
  }

  .tech-tables__image {
    margin-bottom: 40px;

    &::before,
    &::after {
      opacity: 0;
      transition: all 0.2s ease;
    }

    &:hover {
      &::before,
      &::after {
        opacity: 1;
        transition: all 0.2s ease;
      }
    }

    &--small {
      display: inline-block;
      width: auto;

      & picture,
      & img {
        width: auto;
        max-width: 100%;
      }
    }
  }

  .tech-tables__info {
    margin-bottom: 40px;
    padding: 30px;
    padding-left: 60px;

    & p {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .tech-tables__desc {
    margin-bottom: 40px;

    & > div {
      width: calc(50% - 100px);
      margin-bottom: 30px;
    }

    & figure {
      & figcaption {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 1.4;
      }
    }
  }

  .tech-tables__caption {
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 20px;
  }

  .tech-tables__text {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 24px;

    &--margin-bottom {
      margin-bottom: 30px;
    }
  }

  .tech-tables__figure {
    margin-bottom: 40px;

    & figcaption {
      padding: 0;
      font-size: 16px;
      line-height: 1.4;
    }

    &--size-m {
      display: block;
      width: calc(100% / 3 - 10px);
      margin-top: -320px;
    }

    &--size-s {
      width: 400px;
    }

    &--size-2m {
      width: 822px;
      margin-left: 10px;
    }
  }

  .tech-tables__sublist {
    margin-bottom: 20px;
    column-count: 2;
    column-gap: 100px;

    & li {
      font-size: 16px;
      line-height: 1.4;

      &::before {
        width: 10px;
        height: 10px;
      }
    }
  }

  .tech-tables__flex {
    &--size-m {
      & > .tech-tables__image {
        width: calc(100% / 3 - 10px);
        margin-bottom: 0;
      }
    }
  }

  .tech-tables__wrap-image {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & .tech-tables__image {
      width: calc(50% - 10px);
      margin-bottom: 20px;

      &:last-of-type,
      &:nth-of-type(2) {
        margin-bottom: 0;
      }
    }
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .tech-tables__title {
    margin-bottom: 80px;
  }

  .tech-tables__subtitle {
    font-size: 26px;

    &::before {
      top: 12px;
    }
  }

  .tech-tables__wrap {
    margin-bottom: 60px;

    &--medium {
      grid-template-columns: repeat(3, minmax(260px, 1fr)) 1fr;
    }
  }

  .tech-tables__image {
    display: inline-block;
    margin-bottom: 60px;

    & picture,
    & img {
      width: auto;
      max-width: 100%;
    }
  }

  .tech-tables__info {
    margin-bottom: 60px;
    padding: 35px;
    padding-left: 60px;

    & p {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .tech-tables__desc {
    margin-bottom: 60px;

    & > div {
      width: calc(50% - 150px);
      margin-bottom: 40px;
    }
  }

  .tech-tables__text {
    font-size: 18px;
    line-height: 26px;

    &--margin-bottom {
      margin-bottom: 40px;
    }
  }

  .tech-tables__figure {
    margin-bottom: 40px;

    &--size-m {
      display: block;
      width: 420px;
      margin-top: -332px;
      margin-right: 360px;
    }

    &--size-s {
      width: 445px;
    }

    &--size-2m {
      width: 915px;
      margin-left: 50px;
    }
  }

  .tech-tables__sublist {
    & li {
      font-size: 18px;

      &::before {
        top: 7px;
      }
    }
  }

  .tech-tables__flex {
    &--size-s {
      justify-content: flex-start;

      & > .tech-tables__image {
        width: auto;
        margin-bottom: 0;
        margin-right: 20px;
      }
    }

    &--size-m {
      justify-content: flex-start;

      & > .tech-tables__image {
        width: auto;
        margin-bottom: 0;
        margin-right: 20px;
      }
    }
  }

  .tech-tables__wrap-image {
    justify-content: flex-start;

    & .tech-tables__image {
      width: auto;
      margin-bottom: 20px;
      margin-right: 20px;

      &:nth-of-type(2n + 2) {
        margin-right: 0;
      }

      &:last-of-type,
      &:nth-of-type(2) {
        margin-bottom: 0;
      }
    }
  }
}
