.products {
  margin-bottom: 35px;
  padding-top: 35px;
  padding-bottom: 40px;
  background-color: $light-gray;
}

.products__container {
  @include container;
  position: relative;
  display: flex;
  flex-direction: column;

  &::after {
    content: "";
    position: absolute;
    left: -30px;
    bottom: -30px;
    display: block;
    width: 242px;
    height: 168px;
    background: url("../images/png/catalog.png") no-repeat center/contain;
  }
}

.products__title {
  @include title;
  margin: 0;
  margin-bottom: 30px;
}

.products__slides {
  display: flex;
  flex-direction: column;
  width: 220px;
  margin: 0 auto;
  margin-bottom: 25px;
  overflow: hidden;
}

.products__list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.products__link {
  position: relative;
  display: block;
  width: 220px;
  height: 140px;
  padding: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $base-color;
  background-color: #ffffff;
  text-decoration: none;
  transition: 0.1s;
  overflow: hidden;

  &:hover {
    background-color: $yellow;
    transition: 0.1s;
  }

  &:first-of-type {
    margin-bottom: 10px;
  }

  & span {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -20px;
      display: block;
      width: 30px;
      height: 2px;
      background-color: $base-color;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -22px;
      left: 26px;
      display: block;
      width: 6px;
      height: 6px;
      border-top: 2px solid $base-color;
      border-right: 2px solid $base-color;
      transform: rotate(45deg);
    }
  }

  & img {
    position: absolute;
    bottom: -10px;
    right: -10px;
    display: block;
    width: 150px;
    height: 100px;
    object-fit: contain;
  }
}

.products__arrows {
  align-self: center;
  display: flex;
  margin-bottom: 20px;
}

.products__btn {
  &--next {
    margin-left: 10px;
    transform: rotate(180deg);
  }
}

.products__more {
  position: relative;
  align-self: center;
  display: block;
  margin-bottom: 110px;
  padding-right: 40px;
  font-size: 13px;
  line-height: 24px;
  color: $base-color;
  text-decoration: none;

  & span {
    position: absolute;
    top: -3px;
    right: 0;
    transform: rotate(180deg);
  }

  &:hover {
    & span {
      @extend .round-button:hover;
    }
  }
}

.products__download {
  position: relative;
  z-index: 1;
  order: 1;
  align-self: flex-end;
  padding-left: 45px;

  &::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 15px;
    display: block;
    width: 17px;
    height: 17px;
    background: url("../images/png/download.png") no-repeat center/contain;
  }

  &:hover {
    filter: brightness(120%);
  }
}

@media (min-width: 500px) {
  .products__slides {
    width: 450px;
  }
}

@media (min-width: $breakpoint-tablet) {
  .products {
    margin-bottom: 40px;
    padding: 40px 0;
  }

  .products__container {
    &::after {
      left: -160px;
      bottom: 40px;
      width: 455px;
      height: 316px;
      background-image: url("../images/png/catalog-tablet.png");
    }
  }

  .products__slides {
    position: relative;
    z-index: 2;
    align-self: flex-end;
    width: 460px;
    margin: 0;
    margin-bottom: 20px;
  }

  .products__arrows {
    align-self: flex-end;
    margin-right: 10px;
  }

  .products__link {
    &:first-of-type {
      margin-bottom: 15px;
    }
  }

  .products__download {
    order: 0;
    align-self: flex-start;
    width: 220px;
    padding: 15px 20px;
    padding-left: 45px;

    &::before {
      top: 12px;
      left: 32px;
    }
  }

  .products__more {
    align-self: flex-end;
    margin: 0;
    margin-right: 10px;
    margin-top: -35px;
  }
}

@media (min-width: $breakpoint-desktop) {
  .products {
    margin-bottom: 70px;
    padding-top: 70px;
    padding-bottom: 50px;
  }

  .products__container {
    &::after {
      left: -250px;
      bottom: 60px;
      width: 675px;
      height: 471px;
      background-image: url("../images/png/catalog-desktop.png");
    }
  }

  .products__title {
    margin-bottom: 0;
  }

  .products__slides {
    width: 800px;
    margin-top: -35px;
  }

  .products__arrows {
    margin-bottom: 40px;
  }

  .products__link {
    width: 255px;
    height: 200px;
    font-size: 18px;
    line-height: 24px;

    & span {
      &::before {
        bottom: -30px;
      }

      &::after {
        bottom: -32px;
      }
    }

    & img {
      right: -20px;
      width: 200px;
      height: 120px;
    }
  }

  .products__download {
    min-width: 280px;
    padding: 20px 25px;
    padding-left: 50px;

    &::before {
      top: 14px;
      left: 40px;
      width: 26px;
      height: 26px;
    }
  }

  .products__more {
    padding-right: 45px;
    font-size: 16px;
    line-height: 24px;

    & span {
      top: -6px;
    }
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .products {
    margin-bottom: 100px;
    padding-top: 80px;
  }

  .products__container {
    &::after {
      left: -300px;
      bottom: -10px;
      width: 945px;
      height: 659px;
      background-image: url("../images/png/catalog-big-desktop.png");
    }
  }

  .products__slides {
    width: 1000px;
  }

  .products__arrows {
    margin-bottom: 50px;
  }

  .products__link {
    width: 320px;
    height: 200px;
  }

  .products__download {
    margin-left: 240px;
  }
}
