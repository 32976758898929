.round-button {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 0;
  line-height: 0;
  background-color: transparent;
  border: 2px solid $gray;
  border-radius: 50%;
  transition: 0.2s ease;

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 10px;
    display: block;
    width: 8px;
    height: 8px;
    border-left: 2px solid $yellow;
    border-bottom: 2px solid $yellow;
    transform: rotate(45deg);
  }

  &:hover {
    background-color: $yellow;
    border-color: $yellow;
    transition: 0.2s ease;

    &::before {
      border-color: #000000;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .round-button {
    width: 35px;
    height: 35px;

    &::before {
      top: 10px;
      left: 12px;
      width: 10px;
      height: 10px;
    }
  }
}
