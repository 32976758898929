.form {
  margin: 40px 0;

  & form {
    background-color: #ffffff;
    box-shadow: 0 0 16px rgba(19, 22, 26, 0.2);
  }
}

.form__container {
  @include container;
}

.form__title {
  @include title;
  margin: 0;
  margin-bottom: 15px;
}

.form__info {
  margin: 0;
  margin-bottom: 30px;
  padding: 0 10px;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
}

.form__image {
  display: none;
}

.form__content {
  display: flex;
  flex-direction: column;
  padding: 30px 15px;
}

.form__label {
  margin-bottom: 25px;
}

.form__input {
  width: 100%;
  padding: 5px 10px;
  font-family: inherit;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  opacity: 0.55;
}

.form__textarea {
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  font-family: inherit;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  background-color: transparent;
  resize: none;
  border: none;
  border-bottom: 1px solid #000000;
  opacity: 0.55;
}

.form__agree {
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  & p {
    width: calc(100% - 35px);
    margin: 0;
    font-size: 13px;
    line-height: 17px;
  }

  & input[type="checkbox"]:checked + .form__checkbox::before {
    content: "";
    position: absolute;
    top: 5px;
    left: 4px;
    display: block;
    width: 15px;
    height: 8px;
    border-left: 2px solid $yellow;
    border-bottom: 2px solid $yellow;
    transform: rotate(-45deg);
  }
}

.form__checkbox {
  position: relative;
  display: block;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  border: 1px solid $base-color;
}

.form__upload-file {
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;
  max-width: 280px;
  min-height: 30px;
  margin-bottom: 30px;
  overflow: hidden;

  & input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  & label {
    position: relative;
    padding-left: 35px;

    &::before {
      content: "";
      position: absolute;
      top: -4px;
      left: 0;
      display: block;
      width: 23px;
      height: 25px;
      background: url("../images/png/paper-clip.png") no-repeat center/contain;
    }
  }

  &:hover {
    & label::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 35px;
      right: 0;
      height: 1px;
      background-color: $yellow;
    }
  }
}

.form__btn {
  align-self: center;
  width: 220px;

  &:hover {
    filter: brightness(120%);
  }
}

@media (min-width: $breakpoint-tablet) {
  .form {
    margin: 80px 0;
  }

  .form__title {
    margin-bottom: 25px;
    text-align: left;
  }

  .form__info {
    margin-bottom: 35px;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
  }

  .form__content {
    padding: 50px 120px;
  }

  .form__input,
  .form__textarea {
    font-size: 14px;
    line-height: 24px;
  }

  .form__textarea {
    height: 50px;
  }

  .form__agree {
    margin: 15px 0 35px 10px;

    & p {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .form__upload-file {
    min-height: 40px;
    font-size: 14px;
    line-height: 24px;

    & label::before {
      top: -3px;
    }
  }

  .form__btn {
    padding: 15px 25px;
    font-size: 16px;
    line-height: 16px;
  }
}

@media (min-width: $breakpoint-desktop) {
  .form {
    margin: 80px 0;
    padding-bottom: 100px;
    padding-top: 100px;
    overflow: hidden;

    & form {
      position: relative;
      z-index: 2;
      display: flex;
      width: 1070px;
      margin: 0 auto;
      padding: 0;
    }
  }

  .form__container {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -500px;
      left: -1215px;
      display: block;
      width: 1315px;
      height: 1496px;
      background: url("../images/svg/decor.svg") no-repeat center/contain;
      opacity: 0.7;
    }

    &::after {
      content: "";
      position: absolute;
      top: -500px;
      right: -1215px;
      display: block;
      width: 1315px;
      height: 1496px;
      background: url("../images/svg/decor.svg") no-repeat center/contain;
      transform: scaleX(-1);
      filter: "FlipH";
      opacity: 0.7;
    }
  }

  .form__image {
    display: block;
    width: 425px;
    min-height: 100%;
    object-fit: cover;
  }

  .form__content {
    flex-grow: 1;
    padding: 70px 70px 60px 80px;
  }

  .form__title {
    margin-bottom: 40px;
    font-size: 40px;
    line-height: 44px;
  }

  .form__info {
    margin-bottom: 40px;
    padding: 0;
    font-size: 16px;
    line-height: 24px;
  }

  .form__label {
    margin-bottom: 30px;
  }

  .form__input,
  .form__textarea {
    font-size: 16px;
    line-height: 24px;
  }

  .form__textarea {
    height: 60px;
  }

  .form__agree {
    & p {
      font-size: 16px;
      line-height: 18px;
    }

    & input[type="checkbox"]:checked + .form__checkbox::before {
      top: 6px;
      left: 5px;
      width: 18px;
      height: 10px;
      border-width: 3px;
    }
  }

  .form__checkbox {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }

  .form__upload-file {
    max-width: 300px;

    & label {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .form {
    padding-top: 120px;
    padding-bottom: 120px;

    & form {
      width: 1300px;
    }
  }

  .form__container {
    &::before {
      left: -1130px;
      top: -485px;
    }

    &::after {
      right: -1130px;
      top: -485px;
    }
  }

  .form__image {
    width: 480px;
  }

  .form__content {
    padding: 80px 160px 70px 140px;
  }
}
