.search {
  position: relative;
  z-index: 25;
  display: block;
  width: 24px;
  height: 23px;

  &.open {
    & .search__btn {
      background-image: url("../images/svg/search-hover.svg");
    }

    & .search__wrapper {
      top: -6px;
      left: -6px;
      width: 210px;
      height: 36px;
      transition: all 0.3s ease;
    }
  }
}

.search__btn {
  position: relative;
  z-index: 2;
  display: inline-block;
  width: 24px;
  height: 23px;
  padding: 0;
  background: transparent url("../images/svg/search-white.svg") no-repeat center/contain;
  border: none;

  &:hover {
    background-image: url("../images/svg/search-hover.svg");
  }
}

.search__wrapper {
  position: absolute;
  top: 8px;
  left: 11px;
  width: 0;
  height: 0;
  background-color: #ffffff;
  box-shadow: 0 0 16px rgba(19,22,26,0.2);
  transition: all 0.3s ease;
  overflow: hidden;
}

.search__label {
  display: flex;
  padding: 8px;
  padding-left: 40px;
}

.search__input {
  flex-grow: 1;
  padding: 0 5px;
  font-family: inherit;
  font-size: 13px;
  line-height: 19px;
  color: $base-color;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $base-color;
}

@media (min-width: $breakpoint-tablet) {
  .search {
    &.open {
      & .search__wrapper {
        left: auto;
        right: -6px;
        width: 300px;
      }
    }
  }

  .search__wrapper {
    right: 11px;
    left: auto;
  }

  .search__label {
    padding-left: 8px;
    padding-right: 40px;
  }
}

@media (min-width: $breakpoint-desktop) {
  .search {
    &.open {
      & .search__wrapper {
        top: -8px;
        height: 40px;
      }
    }
  }

  .search__input {
    font-size: 16px;
    line-height: 22px;
  }
}
