.steps__page {
  margin-bottom: 60px;
  padding-top: 115px;
}

.steps__container {
  @include container;
}

.steps__title {
  @include title;
  margin: 0;
  margin-bottom: 25px;
}

.steps__gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 220px;
  grid-gap: 10px;
  margin: 0;
  margin-bottom: 60px;
  padding: 0;
  list-style: none;

  & li {
    border: 1px solid $gray;
  }
}

.steps__image {
  display: block;
  width: 100%;
  height: 100%;

  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--contain {
    padding: 10px;

    & img {
      object-fit: contain;
    }
  }
}

.steps__video {
  position: relative;
  display: block;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border: 25px solid $yellow;
    border-right: none;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-width: 30px;
    transform: translateY(-50%) translateX(-50%);
  }
}

.steps__subtitle {
  margin: 0;
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.4;
  text-align: center;
}

.steps__links {
  margin: 0;
  margin-bottom: 45px;
  padding: 0;
  list-style: none;

  & li {
    position: relative;
    margin-bottom: 40px;
    padding-left: 70px;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: -17px;
      left: 5px;
      display: block;
      width: 50px;
      height: 50px;
      background: url("../images/png/icon-arrow.png") no-repeat center/contain;
    }
  }
}

.steps__link {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: $base-color;
  text-decoration: none;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -3px;
    height: 1px;
    background-color: $yellow;
  }

  &:hover {
    &::before {
      content: none;
    }
  }
}

.steps__list-tabs {
  margin: 0;
  padding: 0;
  list-style: none;

  & li {
    border: 1px solid $yellow;

    &:first-child {
      border-bottom: none;
    }
  }
}

.steps__btn-tabs {
  display: block;
  width: 100%;
  min-height: 80px;
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  background-color: transparent;
  border: none;

  &.active {
    background-color: $yellow;
  }
}

.steps__contents-tabs {
  display: none;

  &.active {
    display: block;
    margin-bottom: 40px;
  }
}

.steps__text {
  margin: 0;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 19px;
}

.steps__list {
  margin: 20px 0;
  padding: 0;
  list-style: none;

  & li {
    position: relative;
    margin-bottom: 10px;
    padding-left: 25px;
    font-size: 13px;
    line-height: 19px;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: 4px;
      left: 5px;
      display: block;
      width: 8px;
      height: 8px;
      background-color: $yellow;
      transform: rotate(45deg);
    }

    & b {
      display: block;
      font-weight: 500;
      margin-bottom: 5px;
    }

    & p {
      margin: 0;
    }
  }
}

.steps__slides {
  margin-bottom: 40px;
}

.steps__track {
  margin-bottom: 25px;
  overflow: hidden;
}

.steps__slider-list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;

  & .steps__image {
    display: block;
    width: 100%;
    height: 200px;

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.steps__arrows {
  display: flex;
  justify-content: center;
}

.steps__btn-slider {
  &--next {
    margin-left: 10px;
    transform: rotate(180deg);
  }
}

@media (min-width: $breakpoint-tablet) {
  .steps__page {
    margin-bottom: 100px;
    padding-top: 150px;
  }

  .steps__title {
    margin-bottom: 30px;
  }

  .steps__gallery {
    grid-auto-rows: 300px;
    grid-gap: 20px;

    & li.span-2 {
      grid-column: span 2;
    }
  }

  .steps__subtitle {
    margin-bottom: 35px;
    font-size: 24px;
    text-align: left;
  }

  .steps__links {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 80px;

    & li {
      margin-bottom: 45px;
      margin-right: 40px;

      &::before {
        top: -15px;
      }
    }
  }

  .steps__link {
    font-size: 18px;
    line-height: 1.4;
  }

  .steps__btn-tabs {
    font-size: 16px;
    line-height: 1.4;
  }

  .steps__contents-tabs {
    &.active {
      margin-bottom: 60px;
    }
  }

  .steps__text {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 20px;
  }

  .steps__list {
    margin: 30px 0;

    & li {
      font-size: 14px;
      line-height: 20px;

      &::before {
        top: 5px;
        width: 10px;
        height: 10px;
      }
    }
  }

  .steps__slides {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .steps__slider-list {
    & .steps__image {
      height: 300px;
    }
  }

  .steps__arrows {
    justify-content: flex-end;
  }

  .steps__products-more.products-page__list {
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
    margin-bottom: 80px;
  }
}

@media (min-width: $breakpoint-desktop) {
  .steps__page {
    & .form {
      overflow: visible;
    }
  }

  .steps__title {
    margin-bottom: 60px;
  }

  .steps__faq.service__faq-wrap {
    margin-bottom: 80px;
  }

  .steps__gallery {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 330px;
    grid-gap: 25px;
    margin-bottom: 80px;

    & li.span-2 {
      grid-column: auto;
    }
  }

  .steps__subtitle {
    margin-bottom: 40px;
    font-size: 36px;
  }

  .steps__links {
    justify-content: space-between;
    margin-bottom: 100px;
    margin-top: 60px;

    & li {
      margin: 0;
      padding-left: 95px;

      &::before {
        top: -20px;
        width: 74px;
        height: 74px;
      }
    }
  }

  .steps__link {
    font-size: 20px;
  }

  .steps__list-tabs {
    display: flex;

    & li {
      width: 50%;

      &:first-child {
        border-bottom: 1px solid $yellow;
        border-right: none;
      }
    }
  }

  .steps__btn-tabs {
    font-size: 18px;
  }

  .steps__contents-tabs {
    &.active {
      margin-bottom: 90px;
    }
  }

  .steps__text {
    font-size: 16px;
    line-height: 24px;
  }

  .steps__list {
    display: flex;
    justify-content: space-between;
    margin: 40px 0;

    & li {
      width: calc(100% / 3);
      margin: 0;
      padding-left: 30px;
      font-size: 16px;
      line-height: 24px;

      &::before {
        top: 6px;
      }
    }
  }

  .steps__slider-list {
    & .steps__image {
      height: 280px;
    }
  }

  .steps__products-more.products-page__list {
    position: relative;
    z-index: 3;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .steps__title {
    margin-bottom: 80px;
  }

  .steps__gallery {
    grid-auto-rows: 450px;
    margin-bottom: 100px;
  }

  .steps__links {
    justify-content: flex-start;

    & li {
      margin-right: 100px;
      padding-left: 100px;
    }
  }

  .steps__link {
    font-size: 24px;
  }

  .steps__btn-tabs {
    font-size: 20px;
  }

  .steps__text {
    font-size: 18px;
    line-height: 26px;
  }

  .steps__list {
    & li {
      font-size: 18px;
      line-height: 26px;

      &::before {
        top: 8px;
      }
    }
  }

  .steps__slider-list {
    & .steps__image {
      height: 330px;
    }
  }
}
