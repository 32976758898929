.company {
  margin-bottom: 40px;
}

.company__container {
  @include container;
}

.company__title {
  @include title;
  margin: 0;
  margin-bottom: 35px;
}

.company__slider {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.company__track {
  margin-bottom: 30px;
}

.company__list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.company__image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;

  &:first-of-type {
    margin-bottom: 15px;
  }

  & img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    filter: grayscale(100%);
    transition: 0.2s ease;
  }

  &:hover {
    & img {
      filter: grayscale(0);
      transition: 0.2s ease;
    }
  }
}

.company__arrows {
  display: flex;
  align-self: center;
}

.company__btn--next {
  margin-left: 10px;
  transform: rotate(180deg);
}

@media (min-width: $breakpoint-tablet) {
  .company {
    margin-bottom: 40px;
  }

  .company__title {
    margin: 0;
  }

  .company__slider {
    display: flex;
    flex-direction: column-reverse;
  }

  .company__arrows {
    align-self: flex-end;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

@media (min-width: $breakpoint-desktop) {
  .company {
    margin-bottom: 100px;
  }

  .company__track {
    margin-bottom: 0;
  }
}
