.breadcrumbs {
  margin-bottom: 25px;
}

.breadcrumbs__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.breadcrumbs__item {
  margin-right: 5px;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #9e9e9e;

  &::after {
    content: "/";
    margin-left: 5px;
  }

  &:last-child {
    margin-right: 0;

    &::after {
      content: none;
    }
  }
}

.breadcrumbs__link {
  color: $base-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-decoration-color: $yellow;
  }
}

@media (min-width: $breakpoint-desktop) {
  .breadcrumbs {
    margin-bottom: 35px;
  }

  .breadcrumbs__item {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .breadcrumbs {
    margin-bottom: 45px;
  }
}
