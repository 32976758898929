.work {
  margin-bottom: 25px;
}

.work__container {
  @include container;
  display: flex;
  flex-direction: column;
}

.work__title {
  @include title;
  margin: 0;
  margin-bottom: 20px;
}

.work__filter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;

  & .select {
    position: relative;
    width: 210px;
    margin-bottom: 15px;
  }

  & .select__title {
    margin-right: auto;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
  }
}

.work__object {
  z-index: 14;
}

.work__city {
  z-index: 13;
}

.work__product {
  z-index: 12;
}

.work__gallery {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 190px;
  grid-gap: 10px;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  list-style: none;
}

.work__link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  color: #ffffff;
  text-decoration: none;

  &:hover {
    & .work__image::after {
      opacity: 1;
      transition: 0.2s ease;
    }

    & .work__slide-name {
      color: #000000;
    }

    & .work__slide-city {
      color: #000000;

      &::before {
        background-image: url("../images/png/pin.png");
      }
    }
  }
}

.work__image {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(56, 56, 56, 0.5);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(53deg, rgba(255, 255, 255, 0.9) 5%, rgba(252, 204, 15, 0.62));
    background-size: 116%;
    background-position: left bottom;
    opacity: 0;
    transition: 0.2s ease;
  }
}

.work__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
  padding-left: 30px;
}

.work__slide-name {
  margin: 0;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
}

.work__slide-city {
  position: relative;
  margin: 0;
  padding-left: 20px;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;

  &::before {
    content: "";
    position: absolute;
    top: -9px;
    left: 0;
    display: block;
    width: 16px;
    height: 22px;
    background: url("../images/png/pin-white.png") no-repeat center/contain;
  }
}

.work__arrows {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.work__btn--next {
  margin-left: 10px;
  transform: rotate(180deg);
}

.work__more {
  position: relative;
  display: inline-block;
  margin-left: auto;
  padding-right: 35px;
  font-size: 13px;
  line-height: 1.4;
  color: $base-color;
  text-decoration: none;

  & span {
    position: absolute;
    top: -6px;
    right: 0;
    transform: rotate(180deg);
  }
}

@media (min-width: $breakpoint-tablet) {
  .work__title {
    margin-bottom: 35px;
  }

  .work__filter {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    & .select__title {
      font-size: 14px;
      line-height: 16px;
    }
  }

  .work__gallery {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 280px;
    grid-gap: 20px;

    & li:last-child {
      grid-column: 1 / -1;
    }
  }

  .work__slide-name,
  .work__slide-city {
    font-size: 14px;
    line-height: 16px;
  }

  .work__slide-city {
    padding-left: 23px;

    &::before {
      width: 18px;
      height: 24px;
    }
  }

  .work__arrows {
    justify-content: flex-start;
    margin-right: 10px;
  }

  .work__more {
    margin-top: -35px;

    &:hover {
      & span {
        background-color: $yellow;
        border-color: $yellow;

        &::before {
          border-color: #000000;
        }
      }
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .work {
    margin-bottom: 100px;
  }

  .work__title {
    margin-bottom: 60px;
  }

  .work__filter {
    justify-content: center;
    margin-bottom: 40px;

    & .select {
      width: auto;
      margin-bottom: 0;
    }

    & .select__title {
      font-size: 16px;
      line-height: 18px;
    }
  }

  .work__object.select {
    width: 250px;
    margin-right: 40px;
  }

  .work__city.select {
    width: 250px;
    margin-right: 40px;
  }

  .work__product.select {
    width: 270px;
  }

  .work__gallery {
    grid-template-columns: 525px 1fr 1fr;
    grid-template-rows: repeat(3, 315px);

    & li {
      &:nth-child(1) {
        grid-row: 1 / 3;
      }

      &:nth-child(4) {
        grid-column: 2 / -1;
      }

      &:last-child {
        grid-column: auto;
      }
    }
  }

  .work__content {
    padding: 40px 45px;
  }

  .work__slide-name {
    font-size: 16px;
    line-height: 18px;
  }

  .work__slide-city {
    font-size: 16px;
    line-height: 18px;
  }

  .work__more {
    padding-right: 45px;
    font-size: 16px;

    & span {
      top: -8px;
    }
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .work__object.select,
  .work__city.select {
    margin-right: 90px;
  }

  .work__gallery {
    grid-template-columns: 700px 1fr 1fr;
    grid-template-rows: repeat(3, 410px);
    margin-bottom: 40px;
  }
}
