.news-page {
  padding-top: 115px;
}

.news-page__container {
  @include container;
}

.news-page__title {
  @include title;
  margin: 0;
  margin-bottom: 25px;
}

.news-page__filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 20px;
  padding: 0 30px;
}

.news-page__filter-btn {
  display: block;
  margin-right: 15px;
  margin-bottom: 10px;
  padding: 5px 3px;
  font-size: 14px;
  line-height: 14px;
  color: $base-color;
  text-decoration: none;
  border-bottom: 1px solid transparent;

  &:last-of-type {
    margin-right: 0;
  }

  &.active,
  &:hover {
    border-color: $yellow;
  }
}

.news-page__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: 200px;
  grid-gap: 10px;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;
  list-style: none;

  & .news__link {
    width: 100%;
    height: 100%;
  }

  & .news__short-desc {
    display: none;
  }
}

.news-page__btn-more {
  margin-bottom: 40px;
}

@media (min-width: $breakpoint-tablet) {
  .news-page {
    padding-top: 150px;
    margin-bottom: 90px;
  }

  .news-page__filter {
    margin-bottom: 30px;
  }

  .news-page__filter-btn {
    margin-bottom: 0;
    margin-right: 25px;
    padding: 2px  3px;
    font-size: 16px;
    line-height: 16px;
    border-width: 1px;
  }

  .news-page__list {
    grid-auto-rows: 270px;
    grid-gap: 20px;
    margin-bottom: 40px;
  }

  .news-page__btn-more {
    margin-bottom: 60px;
  }
}

@media (min-width: $breakpoint-desktop) {
  .news-page {
    margin-bottom: 0;

    & .form {
      overflow: visible;
    }
  }

  .news-page__title {
    margin-bottom: 60px;
  }

  .news-page__filter {
    margin-bottom: 50px;
  }

  .news-page__list {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 420px;
    width: 1070px;
    margin: 0 auto;
    margin-bottom: 50px;

    & .news__link:hover {
      & .news__short-desc {
        color: #ffffff;
        transition: all 0.2s ease;

        &::after {
          border-color: #ffffff;
        }
      }
    }

    & .news__data {
      font-size: 20px;
      line-height: 28px;

      & span {
        font-size: 48px;
      }
    }

    & .news__text {
      margin-bottom: 15px;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
    }

    & .news__short-desc {
      position: relative;
      display: block;
      margin: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      transition: all 0.2s ease;

      &::after {
        content: "❯❯";
        margin-left: 5px;
        letter-spacing: -3px;
      }
    }
  }

  .news-page__btn-more {
    margin-bottom: 0;
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .news-page__title {
    margin-bottom: 80px;
  }

  .news-page__list {
    grid-auto-rows: 500px;
    width: 1500px;
  }
}
