.mount {
  margin-bottom: 60px;
  padding-top: 115px;
}

.mount__container {
  @include container;
}

.mount__title {
  @include title;
  margin: 0;
  margin-bottom: 25px;
}

.mount__desc {
  margin-bottom: 35px;

  & > p {
    margin: 0;
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 19px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.mount__subtitle {
  margin: 0;
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.mount__table-wrap {
  margin-bottom: 40px;
}

.mount__table {
  border-collapse: collapse;

  & th {
    height: 80px;
    margin: 0;
    padding: 10px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #454547;
    background-color: $yellow;
    text-align: center;
    vertical-align: middle;
    border: 1px solid $gray;
  }

  & tbody {
    width: 100%;

    & tr {
      display: flex;
      flex-wrap: wrap;
      border: 1px solid $gray;
    }
  }

  & td {
    flex-basis: 300px;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 15px;
    font-size: 13px;
    line-height: 19px;
  }

  & img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }

  &--no-head {
    border: none;

    & thead {
      display: none;
    }

    & tr {
      display: flex;
      flex-direction: column;
      height: 100%;
      border: 1px solid $gray;

      & td {
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;
        padding: 0;

        &:last-child {
          padding: 20px;
        }
      }
    }
  }
}

.mount__table-title {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  margin: 0;
  margin-bottom: 20px;
  padding: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #454547;
  background-color: $yellow;
}

.mount__caption {
  margin: 0;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.mount__image-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-bottom: 40px;
}

.mount__image {
  display: block;
  width: 100%;
  height: auto;

  & img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.mount__video-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  margin-bottom: 40px;
}

.mount__video {
  position: relative;
  display: block;
  height: 270px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    border: 25px solid $yellow;
    border-right: none;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-width: 30px;
    transform: translateX(-50%) translateY(-50%);
  }

  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (min-width: $breakpoint-tablet) {
  .mount {
    margin-bottom: 100px;
    padding-top: 150px;
  }

  .mount__title {
    margin-bottom: 30px;
  }

  .mount__desc {
    margin-bottom: 40px;

    & > p {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .mount__subtitle {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 30px;
    text-align: left;
  }

  .mount__table-wrap {
    margin-bottom: 60px;
  }

  .mount__table {
    width: 100%;
    border: 1px solid $gray;
    table-layout: fixed;

    & tbody {
      & tr {
        display: table-row;
      }

      & td {
        padding: 30px;
        font-size: 14px;
        line-height: 20px;
        border: 1px solid $gray;

        &:first-child {
          width: 310px;
        }
      }
    }

    &--no-head {
      display: table;

      & thead {
        display: table-header-group;
      }

      & tr {
        display: table-row;

        & td:last-child {
          padding: 30px;
        }
      }
    }
  }

  .mount__table-title {
    min-height: auto;
    margin: 0;
    margin-bottom: 15px;
    padding: 0;
    background-color: transparent;
  }

  .mount__caption {
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 24px;
  }

  .mount__image-wrap {
    display: block;
  }

  .mount__video-wrap {
    display: block;
    margin-bottom: 60px;
  }

  .mount__video {
    height: 430px;
    margin-bottom: 20px;
  }
}

@media (min-width: $breakpoint-desktop) {
  .mount {
    & .form {
      overflow: visible;
    }
  }

  .mount__title {
    margin-bottom: 60px;
  }

  .mount__desc {
    margin-bottom: 60px;
    column-count: 2;
    column-gap: 80px;

    & > p {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .mount__subtitle {
    margin-bottom: 30px;
    font-size: 36px;
    line-height: 40px;
  }

  .mount__table-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .mount__table {
    width: auto;
    height: 100%;

    & tbody td {
      font-size: 16px;
      line-height: 24px;

      &:first-child {
        width: 230px;
      }
    }

    &--no-head:nth-of-type(n + 3) {
      & thead {
        display: none;
      }
    }
  }

  .mount__table-title {
    text-align: center;
  }

  .mount__caption {
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 24px;
  }

  .mount__image-wrap {
    display: grid;
    grid-template-columns: 530px 1fr;
    grid-gap: 30px;
    margin-bottom: 60px;
  }

  .mount__video-wrap {
    position: relative;
    z-index: 3;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px;
  }

  .mount__video {
    height: 320px;
    margin: 0;
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .mount__title {
    margin-bottom: 80px;
  }

  .mount__desc {
    column-gap: 100px;

    & > p {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .mount__subtitle {
    margin-bottom: 40px;
  }

  .mount__table-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 80px;
  }

  .mount__table {
    & th {
      font-size: 20px;
      line-height: 24px;
    }

    & tbody td {
      padding: 40px;
      font-size: 18px;
      line-height: 26px;

      &:first-child {
        width: 350px;
      }
    }

    &--no-head tr td:last-child {
      padding: 40px;
    }
  }

  .mount__table-title {
    margin-bottom: 25px;
  }

  .mount__caption {
    font-size: 22px;
    line-height: 26px;
  }

  .mount__image-wrap {
    grid-template-columns: 570px 1fr;
    grid-gap: 100px;
    margin-bottom: 80px;
  }

  .mount__video {
    height: 440px;
  }
}
