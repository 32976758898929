.trails {
  margin-bottom: 60px;
  padding-top: 115px;
}

.trails__container {
  @include container;
}

.trails__title {
  @include title;
  margin: 0;
  margin-bottom: 25px;
}

.trails__desc {
  margin-bottom: 30px;

  & > p {
    margin: 0;
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 19px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.trails__subtitle {
  margin: 0;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
}

.trails__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin: 0;
  margin-bottom: 35px;
  padding: 0;
  list-style: none;
}

.trails__item {
  border: 1px solid $gray;
}

.trails__caption {
  margin: 0;
  padding: 25px;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #454547;
  background-color: $yellow;
  text-align: center;
}

.trails__content {
  margin: 0;
  padding: 20px 10px;

  & figcaption {
    font-size: 14px;
    line-height: 20px;
  }
}

.trails__video {
  position: relative;
  display: block;
  height: 160px;
  margin-bottom: 20px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border: 15px solid $yellow;
    border-right: 0;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-width: 20px;
    transform: translateX(-50%) translateY(-50%);
  }

  & picture {
    display: block;
    height: 100%;

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.trails__table-wrap {
  margin-bottom: 40px;
  padding-bottom: 20px;
  overflow-x: auto;
}

.trails__table {
  width: 590px;
  table-layout: fixed;
  border-collapse: collapse;

  & th {
    height: 90px;
    padding: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    background-color: $yellow;
    text-align: center;
    vertical-align: middle;
    border: 1px solid $gray;

    &:nth-child(1) {
      width: 90px;
    }

    &:nth-child(2) {
      width: 100px;
    }

    &:nth-child(3) {
      width: 300px;
    }

    &:nth-child(4) {
      width: 300px;
    }
  }

  & td {
    padding: 10px;
    font-size: 13px;
    line-height: 19px;
    color: #000000;
    vertical-align: middle;
    border: 1px solid $gray;

    &:nth-child(1),
    &:nth-child(2) {
      text-align: center;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .trails {
    margin-bottom: 100px;
    padding-top: 150px;
  }

  .trails__desc {
    margin-bottom: 40px;

    & > p {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .trails__subtitle {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 30px;
    text-align: left;
  }

  .trails__list {
    display: block;
    margin-bottom: 60px;
  }

  .trails__caption {
    font-size: 20px;
    line-height: 28px;
  }

  .trails__content {
    padding: 30px;
    padding-bottom: 40px;

    & figcaption {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .trails__video {
    height: 360px;

    &::after {
      border-width: 25px;
      border-left-width: 30px;
    }
  }

  .trails__table {
    & th {
      font-size: 16px;
      line-height: 24px;

      &:nth-child(1) {
        width: 145px;
      }

      &:nth-child(2) {
        width: 150px;
      }

      &:nth-child(3),
      &:nth-child(4) {
        width: 300px;
      }
    }

    & td {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .trails {
    & .form {
      overflow: visible;
    }
  }

  .trails__title {
    margin-bottom: 60px;
  }

  .trails__desc {
    margin-bottom: 60px;
    column-count: 2;
    column-gap: 80px;

    & > p {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .trails__subtitle {
    margin-bottom: 40px;
    font-size: 36px;
    line-height: 42px;
  }

  .trails__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .trails__content {
    padding: 40px;
    padding-bottom: 60px;
  }

  .trails__table-wrap {
    position: relative;
    z-index: 3;
    margin: 0;
    padding: 0;
  }

  .trails__table {
    width: 100%;

    & th {
      font-size: 18px;
      line-height: 26px;
    }

    & td {
      padding: 15px;
    }
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .trails__title {
    margin-bottom: 80px;
  }

  .trails__desc {
    column-gap: 100px;

    & > p {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .trails__content {
    & figcaption {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .trails__table {
    & th {
      font-size: 20px;
      line-height: 28px;
    }

    & td {
      font-size: 18px;
      line-height: 26px;
    }
  }
}
