.faq {
  margin-bottom: 60px;
  padding-top: 115px;
}

.faq__container {
  @include container;
}

.faq__title {
  @include title;
  margin: 0;
  margin-bottom: 30px;
}

.faq__block {
  margin-bottom: 40px;
}

.faq__caption {
  position: relative;
  margin: 0;
  margin-bottom: 25px;
  padding-left: 55px;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 46px;
    height: 46px;
    margin-top: -23px;
    background: url("../images/png/faq.png") no-repeat center/contain;
  }
}

.faq__list {
  margin: 0;
  padding: 0;
  list-style: none;

  & li {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & p {
    margin: 0;
    font-size: 14px;
    line-height: 20px;

    &:first-child {
      position: relative;
      margin-bottom: 10px;
      padding-left: 25px;
      font-weight: 700;
      font-size: 16px;

      &::before {
        content: "";
        position: absolute;
        top: 4px;
        left: 5px;
        display: block;
        width: 10px;
        height: 10px;
        background-color: $yellow;
        transform: rotate(45deg);
      }
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .faq {
    margin-bottom: 100px;
    padding-top: 150px;
  }

  .faq__title {
    margin-bottom: 40px;
  }

  .faq__block {
    margin-bottom: 60px;
  }

  .faq__caption {
    margin-bottom: 40px;
    padding-left: 80px;
    font-size: 24px;
    line-height: 26px;

    &::before {
      width: 64px;
      height: 64px;
      margin-top: -32px;
    }
  }

  .faq__list {
    & li {
      margin-bottom: 30px;
    }

    & p {
      &:first-child {
        margin-bottom: 15px;
      }
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .faq {
    & .form {
      overflow: visible;
    }
  }

  .faq__title {
    margin-bottom: 60px;
  }

  .faq__block {
    margin-bottom: 80px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .faq__caption {
    font-size: 30px;
    line-height: 32px;
  }

  .faq__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & li {
      width: 47%;

      &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }

    & p {
      font-size: 16px;
      line-height: 24px;

      &:first-child {
        font-size: 20px;

        &::before {
          top: 6px;
        }
      }
    }
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .faq__title {
    margin-bottom: 80px;
  }

  .faq__caption {
    margin-bottom: 60px;
    padding-left: 100px;

    &::before {
      width: 80px;
      height: 80px;
      margin-top: -40px;
    }
  }

  .faq__list {
    & p {
      font-size: 18px;
      line-height: 26px;

      &:first-child {
        font-size: 22px;
      }
    }
  }
}
