.object {
  margin-bottom: 100px;
  padding-top: 115px;
}

.object__container {
  @include container;
}

.object__content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 30px;
}

.object__title {
  @include title;
  margin: 0;
  margin-bottom: 30px;
  text-align: left;
}

.object__list {
  margin: 0;
  padding: 0;
  list-style: none;

  & li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & p {
    margin: 0;
    font-size: 12px;
    line-height: 12px;
    color: #000000;

    &:first-of-type {
      position: relative;
      flex-grow: 1;
      display: flex;

      &::after {
        content: "";
        flex-grow: 1;
        align-self: flex-end;
        display: inline-block;
        height: 2px;
        border-bottom: 1px dotted #000000;
        transform: translateY(-2px);
      }
    }

    &:last-of-type {
      margin-left: 2px;
    }
  }
}

.object__preview {
  display: block;
  margin-bottom: 5px;
}

.object__big-image {
  display: block;
  width: 100%;
  height: auto;

  & img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.object__image-list-wrap {
  width: 100%;
  overflow: hidden;
}

.object__image-list {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
}

.object__image-item {
  width: 32%;

  &:nth-child(n+4) {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
  }

  &:nth-child(3) {
    position: relative;

    &::before {
      content: "+ " attr(data-count);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      line-height: 26px;
      color: #ffffff;
      background-color: rgba(56, 56, 56, 0.5);
    }
  }

  & a {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.object__image {
  display: block;
  width: 100%;
  height: 100%;

  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (min-width: $breakpoint-tablet) {
  .object {
    padding-top: 150px;
  }

  .object__content {
    display: block;
  }

  .object__left-col {
    margin-bottom: 40px;
  }

  .object__list {
    & p {
      font-size: 14px;
      line-height: 14px;
    }
  }

  .object__preview {
    height: 490px;
    margin-bottom: 15px;
  }

  .object__big-image {
    height: 100%;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .object__image-item {
    &:nth-child(3)::before {
      font-size: 36px;
      line-height: 40px;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .object {
    margin-bottom: 150px;
  }

  .object__content {
    display: flex;
    justify-content: space-between;
  }

  .object__left-col {
    width: 610px;
  }

  .object__title {
    margin-bottom: 90px;
  }

  .object__list {
    padding-right: 40px;

    & p {
      font-size: 16px;
      line-height: 16px;
    }
  }

  .object__right-col {
    width: 610px;
  }

  .object__preview {
    height: 400px;
    margin-bottom: 10px;
  }

  .object__image-item {
    height: 170px;
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .object__left-col {
    width: 840px;
  }

  .object__list {
    & p {
      font-size: 18px;
      line-height: 18px;
    }
  }

  .object__right-col {
    width: 725px;
  }

  .object__preview {
    height: 490px;
    margin-bottom: 12px;
  }

  .object__image-item {
    height: 200px;

    &:nth-child(3)::before {
      font-size: 48px;
      line-height: 52px;
    }
  }
}
