*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  min-width: 320px;
  max-width: 1920px;
  min-height: 100vh;
  margin: 0 auto;
  font-family: $ff-helvetica;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: $base-color;
  overflow-x: hidden;
}

body {
  display: flex;
  flex-direction: column;
}

.page {
  flex-grow: 1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: none;
  background-color: rgba(0, 0, 0, 0.6);

  &.show {
    display: block;
  }

  &.show-popup {
    z-index: 1001;
    display: block;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.compensate-for-scrollbar {
  margin: 0 !important;
}

