.slider {
  position: relative;
  min-height: 650px;
  margin-bottom: 25px;
}

.slider__container {
  @include container;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 650px;
}

.slider__title {
  margin: 0;
  margin-bottom: 35px;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  color: #ffffff;
  text-align: center;

  & span {
    display: block;
    font-size: 18px;
  }
}

.slider__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .button {
    min-width: 220px;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.slider__aside {
  display: none;
}

.slider__btn-scroll {
  display: none;
}

.slider__slides {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-color: rgba(48, 48, 48, 0.74);
  }
}

.slider__list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.slider__image {
  & picture {
    display: block;
    width: 100vw;
    height: 650px;

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top left;
    }
  }
}

.slider__arrows {
  display: none;
}

.slider__bullets {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider__bullet {
  display: block;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  padding: 0;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.55);
  border-radius: 50%;

  &:last-of-type {
    margin-right: 0;
  }

  &.glide__bullet--active {
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.55);
  }
}

@media (min-width: $breakpoint-tablet) {
  .slider {
    min-height: 550px;
    margin-bottom: 35px;
  }

  .slider__container {
    align-items: flex-start;
    min-height: 550px;
  }

  .slider__title {
    margin-top: 60px;
    font-size: 48px;
    line-height: 48px;
    text-align: left;

    & span {
      font-size: 30px;
      line-height: 36px;
    }
  }

  .slider__buttons {
    flex-direction: row;

    & .button {
      margin: 0;
      margin-right: 20px;
    }
  }

  .slider__image {
    & picture {
      height: 550px;
    }
  }

  .slider__bullets {
    bottom: 50px;
    right: auto;
    left: calc(50% - 360px);
  }
}

@media (min-width: $breakpoint-desktop) {
  .slider {
    height: 100vh;
    margin-bottom: 0;
  }

  .slider__container {
    height: 100vh;
  }

  .slider__title {
    margin-bottom: 60px;
    font-size: 60px;
    line-height: 60px;

    & span {
      font-size: 36px;
      line-height: 48px;
    }
  }

  .slider__buttons {
    & .button {
      min-width: 290px;
    }
  }

  .slider__aside {
    position: absolute;
    right: calc(50% - 620px);
    bottom: 90px;
    display: block;
  }

  .slider__video {
    position: relative;
    display: block;
    margin-bottom: 25px;
    padding-left: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #ffffff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-decoration-color: $yellow;
    }

    &::before {
      content: "";
      position: absolute;
      top: -6px;
      left: 0;
      display: block;
      width: 26px;
      height: 26px;
      background: url("../images/png/play-button.png") no-repeat center/contain;
    }
  }

  .slider__map {
    position: relative;
    display: block;
    padding-left: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-decoration-color: $yellow;
    }

    &::before {
      content: "";
      position: absolute;
      top: -3px;
      left: 0;
      display: block;
      width: 23px;
      height: 31px;
      background: url("../images/png/placeholder.png") no-repeat center/contain;
    }
  }

  .slider__btn-scroll {
    position: absolute;
    bottom: 90px;
    left: 50%;
    display: block;
    width: 28px;
    height: 57px;
    margin-left: -14px;
    font-size: 0;
    line-height: 0;
    background: url("../images/png/scroll.png") no-repeat center/contain;
  }

  .slider__image {
    & picture {
      height: 100vh;
    }
  }

  .slider__arrows {
    position: absolute;
    right: calc(50% - 620px);
    top: 50%;
    z-index: 2;
    display: block;
  }

  .slider__btn {
    opacity: 0.6;

    &::before {
      border-color: #ffffff;
    }

    &:hover {
      background-color: #ffffff;
      border-color: #ffffff;

      &::before {
        border-color: #000000;
      }
    }

    &--next {
      margin-left: 20px;
      transform: rotate(180deg);
    }
  }

  .slider__bullets {
    left: calc(50% - 620px);
    bottom: 90px;
  }

  .slider__bullet {
    width: 10px;
    height: 10px;

    &.glide__bullet--active {
      width: 14px;
      height: 14px;
    }
  }
}

@media (min-width: $breakpoint-big-desktop) {
  .slider__title {
    font-size: 72px;
    line-height: 72px;

    & span {
      font-size: 40px;
      line-height: 60px;
    }
  }

  .slider__bullets {
    left: calc(50% - 830px);
  }

  .slider__arrows,
  .slider__aside {
    right: calc(50% - 830px);
  }
}
